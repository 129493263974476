// @ts-nocheck
import React, { useState } from 'react';
import { getStatusColorByNumber } from '../../utils/color';
import CustomTooltip from 'components/Tooltip/Tooltip';

import './EnlaceConexion.css';

type Props = {
  enlaces: any;
  showBoundCountries: any;
  showBoundMain: any;
};

const EnlaceConexion: React.FC<Props> = ({ enlaces, showBoundCountries, showBoundMain }) => {
  const [hoverEnlace, setHoverEnlace] = useState(null);
  function getBorder(type) {
    if (type === '1 Gb') {
      return '1px dashed #000000';
    } else if (type === '300 Mb') {
      return '1px dashed #fb34d3';
    } else if (type === '100 Mb') {
      return '1px dashed #ffa55d';
    } else if (type === '120 Mb') {
      return '1px dashed #39ff14';
    }
  }

  function deltaPosition(type) {
    if (type === '1 Gb') {
      return -3;
    } else if (type === '300 Mb') {
      return 0;
    } else if (type === '100 Mb') {
      return -5;
    } else if (type === '120 Mb') {
      return -5;
    }
  }

  function showTip(type) {
    setHoverEnlace(type);
  }

  function hideTip() {
    setHoverEnlace(null);
  }

  function getEnlacesLine() {
    const arrayAuxEnlace = [];
    enlaces.forEach((enlace) => {
      const from = document.getElementById(enlace.from);
      const to = document.getElementById(enlace.to);
      const rectFrom = from.getBoundingClientRect();
      const xToEnlFrom = rectFrom.left;
      const yToEnlFrom = rectFrom.top;
      const rectTo = to.getBoundingClientRect();
      const xToEnlTo = rectTo.left;
      const yToEnlTo = rectTo.top;
      const movableElement = document.getElementById('move-shape');
      const rectMv = movableElement.getBoundingClientRect();
      const mvLeft = rectMv.left;
      const mvTop = rectMv.top;

      let x1 = xToEnlFrom - mvLeft + 25;
      let y1 = yToEnlFrom - mvTop + 15;
      let x2 = xToEnlTo - mvLeft + 25;
      let y2 = yToEnlTo - mvTop + 15;

      let copy_x1 = x1;
      let copy_x2 = x2;
      let copy_y1 = y1;
      let copy_y2 = y2;

      x1 = copy_x1 > copy_x2 ? copy_x1 : copy_x2;
      y1 = copy_x1 > copy_x2 ? copy_y1 : copy_y2;
      x2 = copy_x1 > copy_x2 ? copy_x2 : copy_x1;
      y2 = copy_x1 > copy_x2 ? copy_y2 : copy_y1;

      const length = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
      const angle = Math.atan2(y2 - y1, x2 - x1) * (180 / Math.PI) + 90;

      const topTmpLine = y2 > y1 ? y1 : y2;
      const height = y2 > y1 ? y2 - y1 : y1 - y2;

      const delta = (length - height) / 2;

      const topLine = topTmpLine - delta;

      const showOrNot = (type) => {
        let visible = 'visible';
        if (type === 'main') {
          if (!showBoundMain) {
            visible = 'hidden';
          }
        } else {
          if (!showBoundCountries) {
            visible = 'hidden';
          }
        }
        return visible;
      };

      arrayAuxEnlace.push(
        <>
          <div
            className="line"
            style={{
              position: 'absolute',
              border: `${getBorder(enlace.speed)}`,
              left: `${x2 + (x1 - x2) / 2 + 15}px`,
              top: `${topLine + 20}px`,
              width: `${1}px`,
              height: length,
              transform: `rotate(${angle}deg)`,
              visibility: showOrNot(enlace.type),
            }}
          ></div>
          <div
            className="line-center"
            style={{
              position: 'absolute',
              left: `${x2 + (x1 - x2) / 2 + 15 + deltaPosition(enlace.speed)}px`,
              top: `${topLine + 20}px`,
              width: `${12}px`,
              height: length,
              transform: `rotate(${angle}deg)`,
              display: 'flex',
              alignItems: 'baseline',
              justifyContent: 'center',
              visibility: showOrNot(enlace.type),
            }}
            onMouseEnter={() => showTip(`${enlace.from}_${enlace.to}`)}
            onMouseLeave={() => hideTip()}
          >
            <div
              className="circle"
              style={{
                width: '12px',
                height: '12px',
                borderRadius: '50%',
                backgroundColor: `${getStatusColorByNumber(enlace.status)}`,
              }}
            ></div>
            <div style={{ height: '70%' }} />
          </div>
          {enlace.inSpeed && enlace.outSpeed ? (
            <div
              className="circle"
              style={{
                position: 'absolute',
                left: `${(x1 + x2) / 2}px`,
                top: `${(y2 + y1) / 2}px`,
              }}
            >
              <CustomTooltip
                content={`In: ${enlace.inSpeed}, Out: ${enlace.outSpeed}`}
                direction={'top'}
                show={enlace.from + '_' + enlace.to === hoverEnlace ? true : false}
              ></CustomTooltip>
            </div>
          ) : null}
        </>
      );
    });
    return arrayAuxEnlace;
  }
  return <section className="enlace-conexion-cmpt">{getEnlacesLine()}</section>;
};

export default EnlaceConexion;
