import React from 'react';

import CardInfo from 'components/CardInfo/CardInfo';
import { getFlags, getStatusColorByNumber } from '../../utils/color';

import './DatacenterWrap.css';

type Props = {
  name: any;
  totalAlerts: any;
  arrayDatacenter: any;
};

const DatacenterWrap: React.FC<Props> = ({ name = 'Chile', totalAlerts = 34, arrayDatacenter }) => {
  console.log(arrayDatacenter);
  
  function getDatacenters() {
    // <div className="datacenter-info">
    //   <div className="datacenter-row">
    //     <div className="item-dtinfo">SWITCH</div>
    //     <div className="item-dtinfo">ROUTER</div>
    //     <div className="item-dtinfo">FIREWALL</div>
    //     <div className="item-dtinfo">WIRELESS</div>
    //     <div className="item-dtinfo">ENLACES</div>
    //   </div>
    return arrayDatacenter.map((dt: any, index: number) => (
      <div className="datacenter-info" key={`dt-each-${index}`}>
        <div className="wrap-flag-dt">
          <div
            className="flag-dt"
            style={{
              background: getStatusColorByNumber(dt.status),
              width: '20px',
              height: '20px',
              borderRadius: '4px',
            }}
          ></div>
          <div className="title-dt">
            <span style={{ fontWeight: 'bold', fontSize: '18px' }}>{`${dt.name}`}</span>
            {` | ${dt.alertsDt} Problemas activos`}
          </div>
        </div>
        <div className="datacenter-row">
          <div className="item-dtinfo">SWITCH</div>
          <div className="item-dtinfo">ROUTER</div>
          <div className="item-dtinfo">FIREWALL</div>
          <div className="item-dtinfo">WIRELESS</div>
          <div className="item-dtinfo">COM. UNIFICADAS</div>
          <div className="item-dtinfo">ENLACES</div>
        </div>
        <div className="datacenter-row">
          <div className="item-cardinfo">
            <CardInfo
              type={'info'}
              problems={dt.switch.problemServices}
              status={dt.switch.status}
              label={dt.switch.label}
              info={`${dt.switch.ok} / ${dt.switch.total}`}
              ok={dt.switch.ok}
              total={dt.switch.total}
              device={'switch'}
            />
          </div>
          <div className="item-cardinfo">
            <CardInfo
              type={'info'}
              problems={dt.router.problemServices}
              status={dt.router.status}
              label={dt.router.label}
              info={`${dt.router.ok} / ${dt.router.total}`}
              ok={dt.router.ok}
              total={dt.router.total}
              device={'router'}
            />
          </div>
          <div className="item-cardinfo">
            <CardInfo
              type={'info'}
              problems={dt.firewall.problemServices}
              status={dt.firewall.status}
              label={dt.firewall.label}
              info={`${dt.firewall.ok} / ${dt.firewall.total}`}
              ok={dt.firewall.ok}
              total={dt.firewall.total}
              device={'firewall'}
            />
          </div>
          <div className="item-cardinfo">
            <CardInfo
              type={'info'}
              problems={dt.wireless.problemServices}
              status={dt.wireless.status}
              label={dt.wireless.label}
              info={`${dt.wireless.ok} / ${dt.wireless.total}`}
              ok={dt.wireless.ok}
              total={dt.wireless.total}
              device={'wireless'}
            />
          </div>
          <div className="item-cardinfo">
            <CardInfo
              type={'info'}
              problems={dt.telefonia.problemServices}
              status={dt.telefonia.status}
              label={dt.telefonia.label}
              info={`${dt.telefonia.ok} / ${dt.telefonia.total}`}
              ok={dt.telefonia.ok}
              total={dt.telefonia.total}
              device={'wireless'}
            />
          </div>
          <div className="item-cardinfo">
            <CardInfo
              type={'info'}
              problems={dt.enlaces.problemServices}
              status={dt.enlaces.status}
              label={dt.enlaces.label}
              info={`${dt.enlaces.ok} / ${dt.enlaces.total}`}
              ok={dt.enlaces.ok}
              total={dt.enlaces.total}
              device={'enlaces'}
            />
          </div>
        </div>
      </div>
    ));
    // </div>
  }
  return (
    <section className="datacenter-wrap">
      <div className="title-wrap">
        <div className="title">
          <img src={getFlags(name)} />
        </div>
        {/* <div className="title-alerts">{`${totalAlerts} ALERTAS ACTIVAS TOTALES`}</div> */}
      </div>
      <div className="wrap-datacenters">{getDatacenters()}</div>
    </section>
  );
};

export default DatacenterWrap;
