import React from 'react';
import DeviceIcon from 'utils/DeviceIcon';
import { getStatusColorByType } from '../../utils/color';

import './Card.css';

type Props = {
  icon: any;
  title: any;
  info: any;
  status?: any;
  secondary?: Boolean;
};

const CardTitle: React.FC<Props> = ({ icon, title, info, status, secondary }) => {
  return (
    <div className="dpa-card-title" style={{ borderBottom: secondary ? 'none' : '1px solid #C6CEE6' }}>
      <div className="card-icon" style={{ marginRight: `${!icon ? '0px' : '5px'}` }}>
        {!icon ? null : <DeviceIcon type={icon} color={'#5C606A'} />}
      </div>
      <div className="card-title">{title} </div>
      <div className="card-info" style={{ color: `${status ? getStatusColorByType(status) : ''}` }}>
        {info}{' '}
      </div>
    </div>
  );
};

export default CardTitle;
