// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dpa-card {
  width: 100%;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: left;
  padding: 10px;
  border-radius: 10px;
  color: #5c606a;
}
.dpa-card-title {
  width: 100%;
  display: flex;
  flex-direction: row;
  /* color: #ffffff; */
  border-bottom: 1px solid #C6CEE6;
  margin-bottom: 5px;
  position: relative;
}
.dpa-card-title .card-icon {
  /* width: 15%; */
  /* width: 0%; */
  width: max-content;
}
.dpa-card-title .card-title {
  font-size: 16px;
  width: max-content;
  font-weight: bold;
}
.dpa-card-title .card-info {
  width: max-content;
  text-align: right;
  font-size: x-large;
  position: absolute;
  font-weight: bold;
  right: 0;
}

.dpa-card .dpa-card-states {
  width: 100%;
  display: flex;
  flex-direction: row;
  /* color: #ffffff; */
}

.dpa-card .dpa-card-states .state {
  flex: 1;
  text-align: right;
}
.dpa-card .dpa-card-states .state .state-name {
  font-size: xx-small;
  font-weight: bold;
  text-transform: uppercase;
}
`, "",{"version":3,"sources":["webpack://./components/Card/Card.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,qBAAqB;EACrB,aAAa;EACb,mBAAmB;EACnB,cAAc;AAChB;AACA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,oBAAoB;EACpB,gCAAgC;EAChC,kBAAkB;EAClB,kBAAkB;AACpB;AACA;EACE,gBAAgB;EAChB,eAAe;EACf,kBAAkB;AACpB;AACA;EACE,eAAe;EACf,kBAAkB;EAClB,iBAAiB;AACnB;AACA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;EAClB,kBAAkB;EAClB,iBAAiB;EACjB,QAAQ;AACV;;AAEA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,oBAAoB;AACtB;;AAEA;EACE,OAAO;EACP,iBAAiB;AACnB;AACA;EACE,mBAAmB;EACnB,iBAAiB;EACjB,yBAAyB;AAC3B","sourcesContent":[".dpa-card {\n  width: 100%;\n  /* height: 100%; */\n  display: flex;\n  flex-direction: column;\n  justify-content: left;\n  padding: 10px;\n  border-radius: 10px;\n  color: #5c606a;\n}\n.dpa-card-title {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  /* color: #ffffff; */\n  border-bottom: 1px solid #C6CEE6;\n  margin-bottom: 5px;\n  position: relative;\n}\n.dpa-card-title .card-icon {\n  /* width: 15%; */\n  /* width: 0%; */\n  width: max-content;\n}\n.dpa-card-title .card-title {\n  font-size: 16px;\n  width: max-content;\n  font-weight: bold;\n}\n.dpa-card-title .card-info {\n  width: max-content;\n  text-align: right;\n  font-size: x-large;\n  position: absolute;\n  font-weight: bold;\n  right: 0;\n}\n\n.dpa-card .dpa-card-states {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  /* color: #ffffff; */\n}\n\n.dpa-card .dpa-card-states .state {\n  flex: 1;\n  text-align: right;\n}\n.dpa-card .dpa-card-states .state .state-name {\n  font-size: xx-small;\n  font-weight: bold;\n  text-transform: uppercase;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
