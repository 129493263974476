import React from 'react';
import { getStatusColorByNumber } from '../../utils/color';
import './SdwanCard.css';
// import AWS from '../../img/aws.svg';
// import GCP from '../../img/gcp.png';
// import AZURE from '../../img/azure.webp';
// import ORACLE from '../../img/oracle.png';

// import './CardInfo.css';

type Props = {
  title: any;
  image: any;
  arrayCountry?: any;
  statusCloud: any;
  keyCloud: any;
};

const arrayCountryT = [
  {
    name: 'CHILE',
  },
  {
    name: 'ARGENTINA',
  },
  {
    name: 'BRASIL',
  },
  {
    name: 'PERU',
  },
  {
    name: 'COLOMBIA',
  },
];

const SdwanCard: React.FC<Props> = ({ title, image, arrayCountry = arrayCountryT, statusCloud, keyCloud }) => {
  // function getCard() {
  //   if (type === 'title') {
  //     return <div className="title-card">{name}</div>;
  //   } else if (type === 'info') {
  //     return (
  //       <>
  //         <div className="problems-dt">{`${problems} ALERTAS`}</div>
  //         <div className="title-card">{info}</div>
  //         <div className="info-card">{label}</div>
  //       </>
  //     );
  //   }
  // }
  return (
    <section className="sdwan-card-cmpt">
      <div className="sdwan-card-image">
        <div className="sdwan-card-title">{title}</div>
        <div className="sdwan-card-logo">
          <img src={image} />
        </div>
      </div>
      <div className="sdwan-card-indicator">
        <div className="sdwan-card-wrap-item">
          <div className="flag-card" style={{ background: getStatusColorByNumber(statusCloud['CHILE'][keyCloud]) }}></div>
          <div className="label-card">CHILE</div>
        </div>
        <div className="sdwan-card-wrap-item">
          <div className="flag-card"></div>
          <div className="label-card">ARGENTINA</div>
        </div>
        <div className="sdwan-card-wrap-item">
          <div className="flag-card"></div>
          <div className="label-card">BRASIL</div>
        </div>
        <div className="sdwan-card-wrap-item">
          <div className="flag-card"></div>
          <div className="label-card">PERU</div>
        </div>
        <div className="sdwan-card-wrap-item">
          <div className="flag-card"></div>
          <div className="label-card">COLOMBIA</div>
        </div>
      </div>
      {/* <div className="sdwan-card-graphic">
        <div className="graphic" style={{ width: '78px', height: '11px' }}>
          <div className="graphic-gray" style={{ width: `${20}px` }}></div>
          <div className="graphic-white" style={{ width: `${80}px` }}></div>
        </div>
        <div className="graphic" style={{ width: '78px', height: '11px' }}>
          <div className="graphic-gray" style={{ width: `${60}px` }}></div>
          <div className="graphic-white" style={{ width: `${40}px` }}></div>
        </div>
        <div className="graphic" style={{ width: '78px', height: '11px' }}>
          <div className="graphic-gray" style={{ width: `${40}px` }}></div>
          <div className="graphic-white" style={{ width: `${60}px` }}></div>
        </div>
        <div className="graphic" style={{ width: '78px', height: '11px' }}>
          <div className="graphic-gray" style={{ width: `${30}px` }}></div>
          <div className="graphic-white" style={{ width: `${70}px` }}></div>
        </div>
        <div className="graphic" style={{ width: '78px', height: '11px' }}>
          <div className="graphic-gray" style={{ width: `${15}px` }}></div>
          <div className="graphic-white" style={{ width: `${85}px` }}></div>
        </div>
      </div> */}
    </section>
  );
};

export default SdwanCard;
