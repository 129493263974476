import React, { useState, useEffect } from 'react';

import TableActiveAlerts from 'components/TableActiveAlerts/TableActiveAlerts';

import './NocDetails.css';

type Props = {
  country: any;
  hosts: any;
  onBack: any;
};

const countryExample = {
  name: 'Chile',
  alerts: 12,
};

const tableColumns = [
  {
    label: 'ESTADO',
    field: 'type',
    sort: 'disabled',
    width: 80,
  },
  {
    label: 'REGIÓN',
    field: 'region',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'TIENDA',
    field: 'store',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'CÓDIGO',
    field: 'code',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'SERVICIOS CON PROBLEMAS',
    field: 'active_alarms',
    sort: 'disabled',
    width: 100,
  },
  {
    label: '% SATURACION',
    field: 'saturation',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'DISPOSITIVOS CON PROBLEMAS',
    field: 'devices_state',
    sort: 'disabled',
    width: 100,
  },
  {
    label: 'ROUTERS CON PROBLEMAS',
    field: 'routers_state',
    sort: 'disabled',
    width: 100,
  },
  {
    label: 'SWITCHS CON PROBLEMAS',
    field: 'switchs_state',
    sort: 'disabled',
    width: 100,
  },
  {
    label: 'CON PROBLEMAS DE UPTIME(<30m)',
    field: 'uptime_state',
    sort: 'disabled',
    width: 100,
  },
  {
    label: 'VER TIENDA',
    field: 'see_store',
    sort: 'disabled',
    width: 100,
  },
];

const NocDetailsDatacenter: React.FC<Props> = ({ country = countryExample, hosts, onBack }) => {
  const [resume, setResume] = useState<any>({ totalStoreProblems: 0, storeStates: [], state: 'OK' });

  useEffect(() => {
    setResume({ totalStoreProblems: 0, storeStates: [], state: 'OK' });
  }, [hosts]);
  function getStoresTable() {
    return {
      columns: tableColumns,
      rows: [],
    };
  }
  function getGroupItems() {
    return [];
  }
  return (
    <section className="noc-details">
      <div className="title-wrap">
        <div className="title">{country.name}</div>
        <div className="title-alerts">{`${resume.totalStoreProblems} ALERTAS ACTIVAS TOTALES`}</div>
      </div>
      <div className="noc-details-body">
        <div className="noc-details-side">
          <div className="resume-item"></div>
          <div>
            <button onClick={onBack}>VOLVER</button>
          </div>
        </div>
        <div className="noc-details-content">
          <div className="category-items">{getGroupItems()}</div>
          <div className="active-alerts-noc">
            <TableActiveAlerts data={getStoresTable()} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default NocDetailsDatacenter;
