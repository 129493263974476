import React from 'react';
import { getStatusColorByNumber } from '../../utils/color';

import './CardInfoRedes.css';

type Props = {
  type: any;
  name: any;
  label: any;
  info: any;
  status: any;
  problems: any;
  percentage: any;
};

const CardInfoRedes: React.FC<Props> = ({ status, type, name = '', label = '', info = '', problems, percentage }) => {
  function getCard() {
    if (type === 'title') {
      return <div className="title-card">{name}</div>;
    } else if (type === 'info') {
      return (
        <>
          <div className="problems-dt">{`${problems} ALERTAS`}</div>
          <div className="title-card">{info}</div>
          <div className="info-card">{label}</div>
        </>
      );
    } else if (type === 'supplier') {
      // const grayPixelsDouble = (percentage.problem * 100) / 78;
      const percentageProblem = (percentage.problems * 100) / percentage.elements;

      const grayPixels = (78 * percentageProblem) / 100;
      const whitePixels = 78 - grayPixels;
      return (
        <>
          <div className="header">
            <div className="label">{name}</div>
            {/* <div className="mount">{problems}</div> */}
          </div>
          <div className="body">
            <div className="graphic" style={{ width: '78px', height: '11px' }}>
              <div className="graphic-gray" style={{ width: `${grayPixels}px` }}></div>
              <div className="graphic-white" style={{ width: `${whitePixels}px` }}></div>
            </div>
            <div className="text-body-supplier">
              <div className="percentage">{info}</div>
              <div className="label">{label}</div>
            </div>
          </div>
          {/* <div className="title-card">{info}</div>
          <div className="info-card">{label}</div> */}
        </>
      );
    } else {
      return <div className="title-card">{name}</div>;
    }
  }
  return (
    <section
      className="card-info-redes-basic"
      style={{
        backgroundColor: getStatusColorByNumber(status),
        alignItems: type === 'title' ? 'flex-start' : 'flex-end',
      }}
    >
      {getCard()}
    </section>
  );
};

export default CardInfoRedes;
