// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-dropdown-cmpt {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* height: ; */
}

.card-dropdown-cmpt .card-dropdown-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  font-size: 12px;
  color: #158ad6;
  background: #dde3f0;
  border-radius: 10px;
  font-weight: bold;
}

.card-dropdown-cmpt .card-dropdown-title .card-dropdown-title-label {
  margin-left: 10px;
}

.card-dropdown-cmpt .card-dropdown-title .card-dropdown-title-icon {
  height: 100%;
  cursor: pointer;
  margin-right: 10px;
}

.card-dropdown-cmpt .card-dropdown-title .card-dropdown-title-icon img {
  width: 100%;
  height: 100%;
}

.card-dropdown-cmpt .card-dropdown-content {
  width: 100%;
  background: #dde3f0;
  padding: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
`, "",{"version":3,"sources":["webpack://./components/CardDropdown/CardDropdown.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,YAAY;EACZ,eAAe;EACf,cAAc;EACd,mBAAmB;EACnB,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,mBAAmB;EACnB,aAAa;EACb,+BAA+B;EAC/B,gCAAgC;AAClC","sourcesContent":[".card-dropdown-cmpt {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  /* height: ; */\n}\n\n.card-dropdown-cmpt .card-dropdown-title {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  height: 30px;\n  font-size: 12px;\n  color: #158ad6;\n  background: #dde3f0;\n  border-radius: 10px;\n  font-weight: bold;\n}\n\n.card-dropdown-cmpt .card-dropdown-title .card-dropdown-title-label {\n  margin-left: 10px;\n}\n\n.card-dropdown-cmpt .card-dropdown-title .card-dropdown-title-icon {\n  height: 100%;\n  cursor: pointer;\n  margin-right: 10px;\n}\n\n.card-dropdown-cmpt .card-dropdown-title .card-dropdown-title-icon img {\n  width: 100%;\n  height: 100%;\n}\n\n.card-dropdown-cmpt .card-dropdown-content {\n  width: 100%;\n  background: #dde3f0;\n  padding: 10px;\n  border-bottom-left-radius: 10px;\n  border-bottom-right-radius: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
