import React, { useEffect, useState, useRef } from 'react';
import ChileMapSvg from './Chile';
// import Argentina from './Argentina';
// import Brasil from './Brasil';
// import Peru from './Peru';
// import Colombia from './Colombia';
// import AlertIndicator from './AlertIndicator';
import './GeographicMap.css';

type Props = {
  chooseRegion: any;
  regiones: any;
  info: any;
  regionNumber: any;
  region: any;
  scale: any;
};

const GeographicMapChile: React.FC<Props> = ({ chooseRegion, regiones, info, regionNumber, region, scale }) => {
  const [alerts, setAlerts] = useState<any>([]);
  let ref: any = useRef();

  function showPaintRegions() {
    const arrayAlerts: any [] = [];
    regiones.map((regItem: any, index: number) => {
      const status = regItem.status;
      let labelStatus = 'ok';
      let size = 24;

      let colorButton = '#10D884';
      if (status === 0) {
        colorButton = '#10D884';
      } else if (status === 1) {
        colorButton = '#EFCB0D';
        size = 28;
        labelStatus = 'warning';
      } else if (status === 2) {
        colorButton = '#ED4C5C';
        size = 32;
        labelStatus = 'critical';
      } else if (status === 3) {
        colorButton = '#FF8B1F';
      }

      const delta = index % 2 === 0 ? -20 : 20;

      let elementImageContainer = document.getElementById('image-container');
      const elementImageContainerPosition = elementImageContainer?.getBoundingClientRect();
      const widthImageContainer = elementImageContainerPosition?.width || 0;
      const topImageContainer = elementImageContainerPosition?.top || 0;

      let element: any = document.getElementById(regItem.region);
      const positionImage = element?.getBoundingClientRect();
      const top = positionImage?.top || 0;
      const left = positionImage?.left || 0;
      const width = positionImage?.width || 0;

      arrayAlerts.push(
        <div
          className={`alerts-geomap-item ${labelStatus}`}
          style={{
            position: 'absolute',
            // width: `${width}px`,
            // top: `${top - topImageContainer - 225}px`,
            top: `${(top - topImageContainer) / 1.75}px`,
            left: `${(left - widthImageContainer) / 1.75 + width / 2 + 40 + delta + 220}px`,
            width: `${size}px`,
            height: `${size}px`,
            borderRadius: '50%',
            // background: `${colorButton}`,
            // color: '#ffffff',
            background: `#ffffff`,
            color: `${colorButton}`,
            border: '1px solid #ffffff',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: `${size - 18}px`,
            cursor: 'pointer',
            // top: `${top - topImageContainer}px`,
            // left: `${left - widthImageContainer}px`,
          }}
        >
          {regItem.storeProblems}/{regItem.storeTotal}
        </div>
      );

      element.style.fill = colorButton;
      element.style.stroke = 'white';
      element.addEventListener('mouseover', () => {
        element.style.strokeWidth = '4px';
        element.style.stroke = 'white';
        element.style.cursor = 'pointer';
      });
      element.addEventListener('mouseout', () => {
        element.style.fill = colorButton;
        element.style.stroke = 'white';
        element.style.strokeWidth = '1px';
      });
      // });
    });
    setAlerts(arrayAlerts);

    const movableElement: any = document.getElementById('image-container');

    let initialX: any, initialY: any;
    let isDragging = false;

    function handleMouseDown(event: any) {
      isDragging = true;
      initialX = event.clientX - movableElement.offsetLeft;
      initialY = event.clientY - movableElement.offsetTop;
      document.addEventListener('mousemove', handleMouseMove);
    }

    function handleMouseMove(event: any) {
      if (isDragging) {
        const newX = event.clientX - initialX;
        const newY = event.clientY - initialY;
        movableElement.style.left = newX + 'px';
        movableElement.style.top = newY + 'px';
      }
    }

    function handleMouseUp() {
      isDragging = false;
      document.removeEventListener('mousemove', handleMouseMove);
    }

    movableElement.addEventListener('mousedown', handleMouseDown);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      movableElement.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      showPaintRegions();
      // showAlerts();
    });
    observer.observe(ref.current);
    return () => ref.current && observer.unobserve(ref.current);
  }, [ref, regiones]);

  return (
    <section className="region-map-section" ref={ref}>
      <div className="image-container" id="image-container" style={{ transform: `scale(${scale})`, top: '235px' }}>
        {/* <div className="image-container" id="image-container" style={{ transform: `scale(${scale})` }}> */}
        <ChileMapSvg choose={chooseRegion} regiones={info} />
        {/* <Argentina choose={chooseRegion} regiones={info} /> */}
        {/* <Brasil choose={chooseRegion} regiones={info} /> */}
        {/* <Peru choose={chooseRegion} regiones={info} /> */}
        {/* <Colombia choose={chooseRegion} regiones={info} /> */}
        {alerts}
      </div>
    </section>
  );
};

export default GeographicMapChile;
