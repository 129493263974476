// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.noc-details {
  width: 100%;
  /* height: max-content; */
  min-height: 50px;
  background-color: #eff3fc;
  border-radius: 10px;
  border: 1px solid #c6cee6;
  margin: 10px;
}
.noc-details .title-wrap {
  border-bottom: 1px solid #c6cee6;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}
.noc-details .title-wrap .title {
  font-size: 18px;
  color: #81848e;
  font-weight: bold;
}

.noc-details .title-wrap .title-alerts {
  font-size: 16px;
  color: #81848e;
}
.noc-details-body {
  display: flex;
  flex-direction: row;
  height: calc(100% - 30px);
}

.noc-details-body .noc-details-side {
  width: 15%;
  padding: 10px;
}
.noc-details-body .noc-details-content {
  width: 85%;
  padding: 10px;
  border-left: 1px solid #c6cee6;
  height: 100%;
}
.noc-details-content .category-items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* height: 105px; */
}
.noc-details-content .active-alerts-noc {
  height: calc(100% - 74px);
}
.noc-details-content .category-items .category-item {
  width: 248px;
  padding: 5px 5px;
}
`, "",{"version":3,"sources":["webpack://./views/NocDetails/NocDetails.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,yBAAyB;EACzB,gBAAgB;EAChB,yBAAyB;EACzB,mBAAmB;EACnB,yBAAyB;EACzB,YAAY;AACd;AACA;EACE,gCAAgC;EAChC,YAAY;EACZ,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,eAAe;AACjB;AACA;EACE,eAAe;EACf,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,cAAc;AAChB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,UAAU;EACV,aAAa;AACf;AACA;EACE,UAAU;EACV,aAAa;EACb,8BAA8B;EAC9B,YAAY;AACd;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,mBAAmB;AACrB;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,YAAY;EACZ,gBAAgB;AAClB","sourcesContent":[".noc-details {\n  width: 100%;\n  /* height: max-content; */\n  min-height: 50px;\n  background-color: #eff3fc;\n  border-radius: 10px;\n  border: 1px solid #c6cee6;\n  margin: 10px;\n}\n.noc-details .title-wrap {\n  border-bottom: 1px solid #c6cee6;\n  height: 30px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 0 10px;\n}\n.noc-details .title-wrap .title {\n  font-size: 18px;\n  color: #81848e;\n  font-weight: bold;\n}\n\n.noc-details .title-wrap .title-alerts {\n  font-size: 16px;\n  color: #81848e;\n}\n.noc-details-body {\n  display: flex;\n  flex-direction: row;\n  height: calc(100% - 30px);\n}\n\n.noc-details-body .noc-details-side {\n  width: 15%;\n  padding: 10px;\n}\n.noc-details-body .noc-details-content {\n  width: 85%;\n  padding: 10px;\n  border-left: 1px solid #c6cee6;\n  height: 100%;\n}\n.noc-details-content .category-items {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  /* height: 105px; */\n}\n.noc-details-content .active-alerts-noc {\n  height: calc(100% - 74px);\n}\n.noc-details-content .category-items .category-item {\n  width: 248px;\n  padding: 5px 5px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
