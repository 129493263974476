import { region_dictionary } from '../constants/country_map_info.ts';

function extractRegionFromHostname(hostname) {
  let city = hostname.substring(2, 5);
  return region_dictionary[city] ? city : '';
}

export function processData(data, field_name = '', data_type = null) {
  const series = {};

  data.series.forEach((serie) => {
    if (serie.fields) {
      serie.fields.forEach((field) => {
        if (field.name === field_name) {
          series[serie.refId] = field['values'].map((value) => (data_type === 'json' ? JSON.parse(value) : value));
        }
      });
    }
  });

  return series;
}

export function getHostsServicesMap(services) {
  const hostsServices = {};
  services.forEach((service) => {
    const key = service.host_name;
    if (key) {
      if (!hostsServices[key]) {
        hostsServices[key] = [];
      }
      hostsServices[key].add(service);
    }
  });
  return hostsServices;
}

export function getHostsServicesMapCloud(services, hosts) {
  const hostsServices = {};
  services.forEach((service) => {
    const key = service.host_name;
    const hostSelected = hosts.find((ht) => ht.name === key);
    service.address = hostSelected.address;
    service.site = hostSelected.tags.site;
    service.country = hostSelected.tags.SITE;
    if (key) {
      if (!hostsServices[key]) {
        hostsServices[key] = [];
      }
      hostsServices[key].add(service);
    }
  });
  return hostsServices;
}

export function getGraphicInfo(hosts, links, graphicInfo) {
  // console.log(hosts, links, graphicInfo);
  // 'main;ARG-RR-01;OK;ARG-PE-02|1 Gb|OK,ARG-PE-01|1 Gb|CRITICAL',
  // 'main;CHL-RR-01;OK;PER-PE-02|1 Gb|OK,PER-PE-01|1 Gb|CRITICAL',
  // 'side;ARG-PE-02;OK;ARG-PE-01|1 Gb|OK,COL-PE-02|100 Mb|CRITICAL',
  // 'side;ARG-PE-01;CRITICAL;BRA-PE-01|120 Mb|WARNING,PER-PE-01|100 Mb|CRITICAL',
  // 'side;CHL-PE-01;OK;CHL-PE-02|1 Gb|OK',
  // 'side;CHL-PE-02;CRITICAL;PER-PE-02|100 Mb|CRITICAL,ARG-PE-02|300 Mb|CRITICAL',
  // 'side;PER-PE-02;WARNING;PER-PE-01|1 Gb|OK',
  // 'side;PER-PE-01;OK',
  // 'side;COL-PE-01;OK;COL-PE-02|1 Gb|OK',
  // 'side;COL-PE-02;WARNING',
  // 'side;BRA-PE-02;OK;BRA-PE-01|1 Gb|OK,CHL-PE-02|120 Mb|WARNING',
  // 'side;BRA-PE-01;OK',
  const infoGrapchic = {
    'CHL-RR-01': {
      status: 3,
      type: 'main',
      connections: [],
    },
    'ARG-RR-01': {
      status: 3,
      type: 'main',
      connections: [],
    },
    // 'ARG-PE-02': {
    //   status: 3,
    //   type: 'side',
    //   connections: [],
    // },
    // 'ARG-PE-01': {
    //   status: 3,
    //   type: 'side',
    //   connections: [],
    // },
    // 'CHL-PE01': {
    //   status: 3,
    //   type: 'side',
    //   connections: [],
    // },
    // 'CHL-PE02': {
    //   status: 3,
    //   type: 'side',
    //   connections: [],
    // },
    // 'PER-PE-02': {
    //   status: 3,
    //   type: 'side',
    //   connections: [],
    // },
    'PER-PE-02': {
      status: 3,
      type: 'side',
      connections: [],
    },
    'PER-PE-01': {
      status: 3,
      type: 'side',
      connections: [],
    },
    'COL-PE-01': {
      status: 3,
      type: 'side',
      connections: [],
    },
    'COL-PE-02': {
      status: 3,
      type: 'side',
      connections: [],
    },
    'BRA-PE-02': {
      status: 3,
      type: 'side',
      connections: [],
    },
    'BRA-PE-01': {
      status: 3,
      type: 'side',
      connections: [],
    },
    'ARG-PE-02': {
      status: 3,
      type: 'side',
      connections: [],
    },
    'ARG-PE-01': {
      status: 3,
      type: 'side',
      connections: [],
    },
    'CHL-PE01': {
      status: 3,
      type: 'side',
      connections: [],
    },
    'CHL-PE02': {
      status: 3,
      type: 'side',
      connections: [],
    },
  };
  // 'ARG-RR-01': {
  //   status: 0,
  //   type: 'side',
  //   connections: [],
  // },
  // console.log(hostServices);
  // console.log(links);
  const arrayToGrap = [];
  const arrayToList = [];
  const dictStatus = { OK: 0, WARN: 1, CRIT: 2 };
  try {
    for (const ht in graphicInfo) {
      // if (Object.hasOwnProperty.call(graphicInfo, ht)) {
      const infoEnlace = graphicInfo[ht];
      const enlaceType = infoEnlace.type;
      const connectionsEnlace = infoEnlace.conections;
      const hostForStatus = hosts.find((host) => host.name === ht);
      const statusDevice = hostForStatus.state;
      const ipDevice = hostForStatus.address;
      // console.log(hostForStatus);
      infoGrapchic[ht]['status'] = statusDevice;
      infoGrapchic[ht]['ip'] = ipDevice;
      infoGrapchic[ht]['type'] = enlaceType;
      connectionsEnlace.forEach((cnEn) => {
        const speed = cnEn.speed;
        const to = cnEn.to;
        const label = cnEn.label;
        let enlaceState = 2;
        let problems = 0;
        if (cnEn.interf && cnEn.neighbor) {
          const serviceInterf = links.find((link) => link.service_description === cnEn.interf);
          // console.log(serviceInterf);
          if (serviceInterf) {
            const interfaceStatus = dictStatus[serviceInterf.service_state];
            const valuesIntf = getSaturationFromServiceOutput(serviceInterf.svc_plugin_output);
            if (valuesIntf) {
              infoGrapchic[ht]['output'] = valuesIntf;
            }
            if (interfaceStatus !== 0) {
              problems += 1;
            }
          }
          const serviceNeighbor = links.find((link) => link.service_description === cnEn.neighbor);
          if (serviceNeighbor) {
            const neighborStatus = dictStatus[serviceNeighbor.service_state];
            enlaceState = neighborStatus;
            if (neighborStatus !== 0) {
              problems += 1;
            }
          }
        } else {
          if (cnEn.interf) {
            const serviceInterf = links.find((link) => link.service_description === cnEn.interf);
            const interfaceStatus = dictStatus[serviceInterf.service_state];
            enlaceState = interfaceStatus;
            const valuesIntf = getSaturationFromServiceOutput(serviceInterf.svc_plugin_output);
            if (valuesIntf) {
              infoGrapchic[ht]['output'] = valuesIntf;
            }
            if (interfaceStatus !== 0) {
              problems += 1;
            }
          } else if (cnEn.neighbor) {
            const serviceNeighbor = links.find((link) => link.service_description === cnEn.neighbor);
            const neighborStatus = dictStatus[serviceNeighbor.service_state];
            enlaceState = neighborStatus;
            if (neighborStatus !== 0) {
              problems += 1;
            }
          }
        }
        infoGrapchic[ht]['connections'].push({
          to,
          speed,
          label,
          state: enlaceState,
          problems,
          site: hostForStatus.tags.site,
        });
      });
      // 'side;CHL-PE-02;CRITICAL;PER-PE-02|100 Mb|CRITICAL,ARG-PE-02|300 Mb|CRITICAL',
      // "side;CHL-PE01;0;ARG-PE-01|300 Mb|0,COL-PE-01|100 Mb|0,"
    }
    for (const ht in infoGrapchic) {
      let stringInfo = '';
      // if (Object.hasOwnProperty.call(infoGrapchic, ht)) {
      const host = infoGrapchic[ht];
      stringInfo = `${host.type};${ht};${host.status};`;
      if (host.output) {
        stringInfo = stringInfo + `${host.output.in};${host.output.out};`;
      }
      host.connections.forEach((eachCon) => {
        // console.log(host);
        const hostToStatus = hosts.find((hostParameter) => eachCon.to === hostParameter.name);
        // if (eachCon.output) {
        //   stringInfo =
        //     stringInfo + `${eachCon.to}|${eachCon.speed}|${eachCon.state}|${eachCon.output.in}|${eachCon.output.out},`;
        // } else {
        // }
        stringInfo = stringInfo + `${eachCon.to}|${eachCon.speed}|${eachCon.state}|${eachCon.site},`;
        arrayToList.push({
          from: ht,
          fromStatus: host.status,
          fromIp: host.ip,
          to: eachCon.to,
          label: eachCon.label,
          // toStatus: eachCon.state,
          toStatus: hostToStatus ? hostToStatus.state : 3,
          // toIp: 
          status: eachCon.state,
          speed: eachCon.speed,
          problems: eachCon.problems,
          site: eachCon.site,
        });
      });
      arrayToGrap.push(stringInfo);
      // }
    }
  } catch(e) {
    console.log(e);
    console.log('Error getting infro from datasource');
  }
  // console.log(arrayToGrap, arrayToList);
  return { arrayToGraphic: arrayToGrap, arrayToList };
}

export function getHostDetailDatacenter(hosts, hostServices, links, arrayInterfaces, arrayNeighbors) {
  const alertsByCountry = {};
  const hostWithDetailsDatacenter = [];
  const problemsWithEnlaces = [];
  let allProblemsServ = [];

  hosts.forEach((host) => {
    if (host.tags.TIENDA === 'DATACENTER') {
      hostWithDetailsDatacenter.push(host);
    }
  });
  const hostDetails = addHostDetailDatacenter(hostWithDetailsDatacenter, hostServices);
  const linkDetails = getEnlacesInfo(hostWithDetailsDatacenter, links, arrayInterfaces, arrayNeighbors);

  hostDetails.forEach((host) => {
    if (!alertsByCountry[host.tags['SITE']]) {
      alertsByCountry[host.tags['SITE']] = {};
    }
    if (!alertsByCountry[host.tags['SITE']][host.tags['CODIGO_DE_TIENDA']]) {
      alertsByCountry[host.tags['SITE']][host.tags['CODIGO_DE_TIENDA']] = {
        switch: [],
        router: [],
        firewall: [],
        wireless: [],
        telefonia: [],
      };
    }
    if (host.tags.TIPO_DE_DISPOSITIVO.includes('SWITCH')) {
      alertsByCountry[host.tags['SITE']][host.tags['CODIGO_DE_TIENDA']]['switch'].push(host);
    } else if (host.tags.TIPO_DE_DISPOSITIVO.includes('ROUTER')) {
      alertsByCountry[host.tags['SITE']][host.tags['CODIGO_DE_TIENDA']]['router'].push(host);
    } else if (host.tags.TIPO_DE_DISPOSITIVO.includes('FIREWALL')) {
      alertsByCountry[host.tags['SITE']][host.tags['CODIGO_DE_TIENDA']]['firewall'].push(host);
    } else if (host.tags.TIPO_DE_DISPOSITIVO.includes('WIRELESS')) {
      alertsByCountry[host.tags['SITE']][host.tags['CODIGO_DE_TIENDA']]['wireless'].push(host);
    } else if (host.tags.TIPO_DE_DISPOSITIVO.includes('TELEFONIA')) {
      alertsByCountry[host.tags['SITE']][host.tags['CODIGO_DE_TIENDA']]['telefonia'].push(host);
    }
  });
  const arrayInfoDatacentersByCountry = [];
  for (const country in alertsByCountry) {
    const countryInfo = alertsByCountry[country];
    const infoToDatacenter = { name: country, totalAlerts: 0, arrayDatacenter: [] };
    for (const dataCenter in countryInfo) {
      const infoDatacenterForArray = {
        name: dataCenter,
        status: 0,
        alertsDt: 0,
        switch: {
          total: 0,
          ok: 0,
          label: 'UP / TOTAL',
          status: 0,
          totalServices: 0,
          problemServices: 0,
          arrayServiceProblems: [],
        },
        router: {
          total: 0,
          ok: 0,
          label: 'UP / TOTAL',
          status: 0,
          totalServices: 0,
          problemServices: 0,
          arrayServiceProblems: [],
        },
        firewall: {
          total: 0,
          ok: 0,
          label: 'UP / TOTAL',
          status: 0,
          totalServices: 0,
          problemServices: 0,
          arrayServiceProblems: [],
        },
        wireless: {
          total: 0,
          ok: 0,
          label: 'UP / TOTAL',
          status: 0,
          totalServices: 0,
          problemServices: 0,
          arrayServiceProblems: [],
        },
        enlaces: {
          total: 0,
          ok: 0,
          label: 'UP / TOTAL',
          status: 0,
          totalServices: 0,
          problemServices: 0,
          arrayServiceProblems: [],
        },
        telefonia: {
          total: 0,
          ok: 0,
          label: 'UP / TOTAL',
          status: 0,
          totalServices: 0,
          problemServices: 0,
          arrayServiceProblems: [],
        },
      };
      if (Object.hasOwnProperty.call(linkDetails, country)) {
        const dtCountry = linkDetails[country];
        if (Object.hasOwnProperty.call(dtCountry, dataCenter)) {
          const element = dtCountry[dataCenter];
          // console.log(element);
          infoDatacenterForArray.enlaces.items = element;
          for (const eachEnlace in element) {
            if (Object.hasOwnProperty.call(element, eachEnlace)) {
              const valueEnlace = element[eachEnlace];
              infoDatacenterForArray['enlaces']['total'] += 1;
              let isOk = false;
              infoDatacenterForArray['enlaces']['totalServices'] += 1;
              if (valueEnlace.interface && valueEnlace.neighbor) {
                if (valueEnlace.interface.service_state === 'OK' && valueEnlace.neighbor.service_state === 'OK') {
                  isOk = true;
                } else {
                  if (valueEnlace.interface.service_state !== 'OK') {
                    infoDatacenterForArray['alertsDt'] += 1;
                    infoDatacenterForArray['status'] = 1;
                  } else if (valueEnlace.neighbor.service_state !== 'OK') {
                    infoDatacenterForArray['alertsDt'] += 1;
                    infoDatacenterForArray['status'] = 1;
                  }
                }
              } else {
                if (valueEnlace.interface) {
                  if (valueEnlace.interface.service_state === 'OK') {
                    isOk = true;
                  } else {
                    infoDatacenterForArray['enlaces']['problemServices'] += 1;
                    infoDatacenterForArray['alertsDt'] += 1;
                    infoDatacenterForArray['status'] = 1;
                    problemsWithEnlaces.push(valueEnlace.interface);
                    allProblemsServ.push(valueEnlace.interface);
                  }
                }
                if (valueEnlace.neighbor) {
                  if (valueEnlace.neighbor.service_state === 'OK') {
                    isOk = true;
                  } else {
                    infoDatacenterForArray['enlaces']['problemServices'] += 1;
                    infoDatacenterForArray['alertsDt'] += 1;
                    infoDatacenterForArray['status'] = 1;
                    problemsWithEnlaces.push(valueEnlace.neighbor);
                    allProblemsServ.push(valueEnlace.neighbor);
                  }
                }
              }

              if (isOk) {
                infoDatacenterForArray['enlaces']['ok'] += 1;
              } else {
                infoDatacenterForArray['enlaces']['status'] = 2;
                infoDatacenterForArray['enlaces']['arrayServiceProblems'].push(valueEnlace);
                // problemsWithEnlaces.push(valueEnlace);
              }
            }
          }
        }
      }
      const devices = countryInfo[dataCenter];
      for (const deviceType in devices) {
        const arrayDevices = devices[deviceType];
        arrayDevices.forEach((dv) => {
          if (dv.state === 0) {
            infoDatacenterForArray[deviceType]['ok'] += 1;
          }
          infoDatacenterForArray[deviceType]['total'] += 1;
          infoDatacenterForArray[deviceType]['totalServices'] += dv.num_services;
          infoDatacenterForArray[deviceType]['problemServices'] += dv.serviceProblems;
          infoDatacenterForArray[deviceType]['arrayServiceProblems'].concat(dv.arrayServiceProblems);
          // console.log(dv.arrayServiceProblems);
          const newProblemsArr = allProblemsServ.concat(dv.arrayServiceProblems);
          const problems = [];
          allProblemsServ = newProblemsArr;
          if (allProblemsServ.length) {
            infoDatacenterForArray['status'] = 1;
          }
          infoToDatacenter.totalAlerts += dv.serviceProblems;
          infoDatacenterForArray['alertsDt'] += dv.serviceProblems;
          if (dv.state !== 0) {
            const problems = [];
            infoDatacenterForArray[deviceType]['status'] = 2;
          }
          if (infoDatacenterForArray[deviceType]['status'] !== 2 && dv.arrayServiceProblems.length) {
            infoDatacenterForArray[deviceType]['status'] = 1;
          }
        });
      }
      infoToDatacenter.arrayDatacenter.push(infoDatacenterForArray);
    }
    arrayInfoDatacentersByCountry.push(infoToDatacenter);
  }
  return { arrayInfoDatacentersByCountry, problemsWithEnlaces, allProblemsServ };
}

export function getHostDetail(hosts, hostsServices) {
  const hostWithDetails = [];
  hosts.forEach((host) => {
    const tmp = { ...host };
    tmp.serviceTotalCritical = 0;
    tmp.serviceTotalWarning = 0;
    if (hostsServices[host.name]) {
      tmp['serviceProblems'] = hostsServices[host.name].length;
      hostsServices[host.name].forEach((service) => {
        if (service.state === 2) {
          tmp.serviceTotalCritical += 1;
        } else if (service.state === 1) {
          tmp.serviceTotalWarning += 1;
        }
      });
    } else {
      tmp['serviceProblems'] = 0;
    }
    if (tmp.uptime) {
      tmp.uptime = extractUptimeFromPerfData(tmp.uptime);
    }
    tmp.tags.region = extractRegionFromHostname(host.name);
    hostWithDetails.add(tmp);
  });
  return hostWithDetails;
}

export function getEnlacesInfo(hosts, links, arrayInterfaces, arrayNeighbors) {
  const infoEnlaces = {};
  hosts.forEach((host) => {
    if (!infoEnlaces[host.tags['SITE']]) {
      infoEnlaces[host.tags['SITE']] = {};
    }
    if (!infoEnlaces[host.tags['SITE']][host.tags['CODIGO_DE_TIENDA']]) {
      infoEnlaces[host.tags['SITE']][host.tags['CODIGO_DE_TIENDA']] = {};
    }
    links.forEach((link) => {
      if (host.name === link.host) {
        const indexOfInt = arrayInterfaces.findIndex((element) => element === link.service_description);
        const indexOfNeigh = arrayNeighbors.findIndex((element) => element === link.service_description);
        if (indexOfInt !== -1 || indexOfNeigh !== -1) {
          if (indexOfInt !== -1) {
            if (!infoEnlaces[host.tags['SITE']][host.tags['CODIGO_DE_TIENDA']][indexOfInt]) {
              infoEnlaces[host.tags['SITE']][host.tags['CODIGO_DE_TIENDA']][indexOfInt] = {};
            }
            infoEnlaces[host.tags['SITE']][host.tags['CODIGO_DE_TIENDA']][indexOfInt]['interface'] = {
              host: link.host,
              service_description: link.service_description,
              service_state: link.service_state,
              svc_plugin_output: link.svc_plugin_output,
              svc_state_age: link.svc_state_age,
              ip: host.address,
              metrics: getSaturationFromServiceOutput(link.svc_plugin_output),
              site: host.tags.site,
            };
          }
          if (indexOfNeigh !== -1) {
            if (!infoEnlaces[host.tags['SITE']][host.tags['CODIGO_DE_TIENDA']][indexOfNeigh]) {
              infoEnlaces[host.tags['SITE']][host.tags['CODIGO_DE_TIENDA']][indexOfNeigh] = {};
            }
            infoEnlaces[host.tags['SITE']][host.tags['CODIGO_DE_TIENDA']][indexOfNeigh]['neighbor'] = {
              host: link.host,
              service_description: link.service_description,
              service_state: link.service_state,
              svc_plugin_output: link.svc_plugin_output,
              svc_state_age: link.svc_state_age,
              ip: host.address,
            };
          }
        }
        // if (arrayInterfaces.includes(link.service_description) || arrayNeighbors.includes(link.service_description)) {
        //   infoEnlaces[host.tags['SITE']][host.tags['CODIGO_DE_TIENDA']].push({
        //     host: link.host,
        //     service_description: link.service_description,
        //     service_state: link.service_state,
        //     svc_plugin_output: link.svc_plugin_output,
        //     svc_state_age: link.svc_state_age,
        //   });
        // }
      }
    });
  });
  return infoEnlaces;
}

export function addHostDetailDatacenter(hosts, hostsServices) {
  const hostWithDetails = [];
  hosts.forEach((host) => {
    const tmp = { ...host };
    const problems = [];
    if (hostsServices[host.name]) {
      hostsServices[host.name].forEach((servProblem) => {
        if (servProblem.state !== 0) {
          problems.push(servProblem);
        }
      });
      tmp['serviceProblems'] = problems.length;
      tmp['arrayServiceProblems'] = problems;
    } else {
      tmp['serviceProblems'] = 0;
      tmp['arrayServiceProblems'] = [];
    }
    hostWithDetails.add(tmp);
  });
  return hostWithDetails;
}

export function getDevicesByType(hosts, hostType) {
  return hosts.filter((host) => {
    return host.tags.TIPO_DE_DISPOSITIVO === hostType;
  });
}
function extractUptimeFromPerfData(perfData) {
  //'uptime=5697741;;;;'
  return parseInt(perfData.split(';')[0].split('=')[1]) / 60 / 60;
}
export function getStores(hosts) {
  const stores = {};
  hosts.forEach((host) => {
    const store_key = host.tags.CODIGO_DE_TIENDA;
    if (store_key) {
      if (!stores[store_key]) {
        stores[store_key] = {
          code: host.tags.CODIGO_DE_TIENDA,
          name: host.tags.TIENDA,
          region: host.tags.region,
          saturation: Math.random() * 100,
          hosts: {
            withUptime: 0,
            withUptimeProblems: 0,
          },
          uptimeProblemsPercent: 0,
          devices: {
            total: 0,
            problems: 0,
          },
          hostTotal: 0,
          hostProblems: 0,
          serviceTotal: 0,
          serviceProblems: 0,
          routers: {
            total: 0,
            problems: 0,
            items: [],
          },
          switchs: {
            total: 0,
            problems: 0,
            items: [],
          },
        };
      }

      if (stores[store_key].region === '') {
        stores[store_key].region = host.tags.region;
      }
      if (host.tags.TIPO_DE_DISPOSITIVO === 'ROUTER') {
        stores[store_key].routers.items.add(host);
        stores[store_key].routers.total += 1;
        stores[store_key].routers.problems += host.state !== 0 ? 1 : 0;
      } else if (host.tags.TIPO_DE_DISPOSITIVO === 'SWITCH') {
        stores[store_key].switchs.items.add(host);
        stores[store_key].switchs.total += 1;
        stores[store_key].switchs.problems += host.state !== 0 ? 1 : 0;
      } else {
        stores[store_key].devices.total += 1;
        stores[store_key].devices.problems += host.state !== 0 ? 1 : 0;
      }
      if (host.uptime) {
        stores[store_key].hosts.withUptime += 1;
        stores[store_key].hosts.withUptimeProblems += host.uptime <= 30;
      }
      stores[store_key].hostTotal += 1;
      stores[store_key].hostProblems += host.state !== 0 ? 1 : 0;
      stores[store_key].serviceTotal += host.num_services;
      stores[store_key].serviceProblems += host.serviceProblems;
    }
  });

  const storeList = Object.values(stores);
  storeList.forEach((store) => {
    store['hostProblemsPercent'] = store.devices.total > 0 ? (store.devices.problems / store.devices.total) * 100 : 0;
    store['routersProblemsPercent'] =
      store.routers.total > 0 ? (store.routers.problems / store.routers.total) * 100 : 0;
    store['switchsProblemsPercent'] =
      store.switchs.total > 0 ? (store.switchs.problems / store.switchs.total) * 100 : 0;
    if (
      store.devices.problems > store.devices.total * 0.8 ||
      (store.routersProblemsPercent === 100 && store.switchsProblemsPercent === 100)
    ) {
      store.state = 2;
    } else {
      store.state = 0;
    }
    if (store.hosts.withUptime > 0 && store.hosts.withUptimeProblems >= store.hosts.withUptime * 0.8) {
      store.hasUptimeProblems = true;
      store.uptimeProblemsPercent = store.hosts.withUptimeProblems / store.hosts.withUptime / 100;
    }
    store.criticity =
      (store.hostProblemsPercent +
        store.routersProblemsPercent +
        store.switchsProblemsPercent +
        store.uptimeProblemsPercent) /
      4;
  });

  return storeList;
}
export function getHostGroupsByTag(hosts, tag) {
  const groups = {};
  hosts.forEach((host) => {
    const groupKey = host.tags[tag];
    if (groupKey) {
      if (!groups[groupKey]) {
        groups[groupKey] = {
          key: groupKey,
          hostTotal: 0,
          hostProblems: 0,
          serviceTotal: 0,
          serviceProblems: 0,
          serviceTotalCritical: 0,
          serviceTotalWarning: 0,
          status: 0,
        };
      }
      groups[groupKey].hostTotal += 1;
      groups[groupKey].hostProblems += host.state !== 0 ? 1 : 0;
      groups[groupKey].serviceTotal += host.num_services;
      groups[groupKey].serviceProblems += host.serviceProblems;
      groups[groupKey].serviceTotalCritical += host.serviceTotalCritical;
      groups[groupKey].serviceTotalWarning += host.serviceTotalWarning;
    }
  });
  return Object.values(groups);
}

export function getRegionStatusByStores(stores) {
  const regions = {};
  stores.forEach((store) => {
    if (store.region) {
      if (!regions[store.region]) {
        regions[store.region] = {
          region: region_dictionary[store.region],
          status: store.state,
          storeTotal: 0,
          storeProblems: 0,
          problems: store.serviceProblems,
          percentage: {
            problem: 10,
            all: 100,
          },
        };
      }
      regions[store.region]['storeTotal'] += 1;
      if (store.state !== 0) {
        regions[store.region]['storeProblems'] += 1;
      }

      if (store.state > regions[store.region].status) {
        regions[store.region].status = store.state;
      }
      regions[store.region].problems += store.serviceProblems;
    }
  });
  return Object.values(regions);
}

function getSaturationFromServiceOutput(service_output) {
  const regex = /Speed: (.+), In: (.+) \(\D*(\d+\.\d+)%\), Out: (.+) \(\D*(\d+\.\d+)%\)/;
  const matches = service_output.match(regex);
  if (matches) {
    const speed = matches[1];
    const inData = matches[2];
    const inPercentage = parseFloat(matches[3]);
    const outData = matches[4];
    const outPercentage = parseFloat(matches[5]);
    return {
      speed: speed,
      inPercentage: inPercentage,
      outPercentage: outPercentage,
      in: inData,
      out: outData,
    };
  }
  return null;
}

function getProviderFromInterfaceName(interfaceName) {
  const provider = interfaceName.split('**ENLACE ')[1].split(' ')[0];
  return provider.toLowerCase().split('_')[0];
}

export function getProviderResumeFromDatacenters(datacenterInfo) {
  const providers = {};
  if (!datacenterInfo) {
    return providers;
  }
  datacenterInfo.arrayDatacenter.forEach((datacenter) => {
    Object.values(datacenter.enlaces.items).forEach((enlace) => {
      if (enlace.interface) {
        const provider = getProviderFromInterfaceName(enlace.interface.service_description);
        if (provider) {
          if (!providers[provider]) {
            providers[provider] = {
              name: provider,
              status: 0,
              problems: 0,
              total: 0,
              percentage: {
                in: 0,
                out: 0,
                elements: 0,
                problems: 0,
              },
              links: [],
            };
          }
          if (enlace.interface.metrics) {
            providers[provider].percentage.in += enlace.interface.metrics.inPercentage;
            providers[provider].percentage.out += enlace.interface.metrics.outPercentage;
            providers[provider].percentage.elements += 1;
          }

          providers[provider].links.push(enlace);
          providers[provider].total += 1;
        }
      }
    });
  });
  for (const key in providers) {
    if (Object.hasOwnProperty.call(providers, key)) {
      const provider = providers[key];
      provider.percentage.in = provider.percentage.in / provider.percentage.elements;
      provider.percentage.out = provider.percentage.out / provider.percentage.elements;
    }
  }
  return providers;
}
