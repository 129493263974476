// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar {
    width: 100%;
    height: 100%;
    background-color: #05243b;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .sidebar .country-status {
    margin: 15px 0;
    position: relative;
    cursor: pointer;
  }
  
  .sidebar .country-status .icon-status {
    position: absolute;
    width: 14px;
    height: 14px;
    /* background-color: lawngreen; */
    border-radius: 4px;
    border: 1px solid white;
    top: -2px;
    right: -2px;
  }
  
  .sidebar .country-status img {
    object-fit: cover;
  }
  
  .sidebar .country-status .logo {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    filter: grayscale(100%);
  }
  
  .sidebar .logo.active {
    filter: grayscale(0%);
    border: 2px solid white;
  }
  `, "",{"version":3,"sources":["webpack://./components/Sidebar/Sidebar.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;EACrB;;EAEA;IACE,cAAc;IACd,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,iCAAiC;IACjC,kBAAkB;IAClB,uBAAuB;IACvB,SAAS;IACT,WAAW;EACb;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,uBAAuB;EACzB;;EAEA;IACE,qBAAqB;IACrB,uBAAuB;EACzB","sourcesContent":[".sidebar {\n    width: 100%;\n    height: 100%;\n    background-color: #05243b;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n  }\n  \n  .sidebar .country-status {\n    margin: 15px 0;\n    position: relative;\n    cursor: pointer;\n  }\n  \n  .sidebar .country-status .icon-status {\n    position: absolute;\n    width: 14px;\n    height: 14px;\n    /* background-color: lawngreen; */\n    border-radius: 4px;\n    border: 1px solid white;\n    top: -2px;\n    right: -2px;\n  }\n  \n  .sidebar .country-status img {\n    object-fit: cover;\n  }\n  \n  .sidebar .country-status .logo {\n    height: 40px;\n    width: 40px;\n    border-radius: 50%;\n    filter: grayscale(100%);\n  }\n  \n  .sidebar .logo.active {\n    filter: grayscale(0%);\n    border: 2px solid white;\n  }\n  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
