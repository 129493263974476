import React, { useState } from 'react';

import './Tooltip.css';

type Props = {
  children?: any;
  delay?: any;
  direction: any;
  content: any;
  show: any;
  angle?: any;
};

const CustomTooltip: React.FC<Props> = ({ children, delay, direction, content, show = false, angle = 0 }) => {
  let timeout: any;
  const [active, setActive] = useState(false);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, delay || 100);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  return (
    <section className="Tooltip-Wrapper" onMouseEnter={showTip} onMouseLeave={hideTip}>
      {children}
      {(active || show) && (
        <div className={`Tooltip-Tip ${direction || 'top'}`} style={{ transform: `rotate(${360 - angle}deg)` }}>
          {content}
        </div>
      )}
    </section>
  );
};

export default CustomTooltip;
