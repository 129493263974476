import React, { useState, useEffect } from 'react';

import DatacenterWrap from 'components/DatacenterWrap/DatacenterWrap';
import Enlace from 'components/Enlace/Enlace';
import Network from 'components/Network/Network';
import SdwanCard from 'components/SdwanCard/SdwanCard';
import TableActiveAlerts from 'components/TableActiveAlerts/TableActiveAlerts';
import AWS from '../../img/aws.svg';
import GCP from '../../img/gcp.png';
import AZURE from '../../img/azure.png';
// import ORACLE from '../../img/oracle.png';

import { getStatusColorByType, getStatusColorByNumber } from '../../utils/color';
//@ts-ignore
import { buildHostUrl } from 'utils/common.js';

import './DatacenterPanel.css';

type Props = {
  services: any;
  hosts: any;
  infoDatacenter: any;
  arraytoDraw: any;
  arrayToDrawList: any;
  enlacesProblems: any;
  allProblems: any;
  servicesCloud: any;
  siteUrl: any;
};

const DatacenterPanel: React.FC<Props> = ({
  hosts = [],
  services = [],
  infoDatacenter,
  arraytoDraw,
  arrayToDrawList,
  enlacesProblems,
  allProblems,
  servicesCloud,
  siteUrl,
}) => {
  // const [selectedDashboard, setSelectedDashboard] = useState('main');
  const [selectedDashboard, setSelectedDashboard] = useState('main');
  const [dtCnt, setDtCnt] = useState(infoDatacenter);
  const [dtEnlaces, setDtEnlaces] = useState(arrayToDrawList);
  const [alertRowsToGraphic, setAlertRowsToGraphic] = useState<any[]>([]);
  const [statusCloud, setStatusCloud] = useState({ CHILE: { AZURE: 0, AWS: 0, GCP: 0 } });

  useEffect(() => {
    setDtCnt(infoDatacenter);
  }, [hosts, services, infoDatacenter, arrayToDrawList]);

  useEffect(() => {
    setDtEnlaces(arrayToDrawList);
  }, [arrayToDrawList]);

  function getInfo() {
    return dtCnt.map((dt: any, index: number) => (
      <DatacenterWrap
        key={`dt-inf-${index}`}
        name={dt.name}
        totalAlerts={dt.totalAlerts}
        arrayDatacenter={dt.arrayDatacenter}
      />
    ));
  }

  function compare(a: any, b: any) {
    if (a.last_state_change < b.last_state_change) {
      return -1;
    }
    if (a.last_state_change > b.last_state_change) {
      return 1;
    }
    return 0;
  }

  function getMain(enlaces: any) {
    return (
      <>
        <div className="enlaces">
          <div className="title">Red Internacional</div>
          <div className="action-button" onClick={() => setSelectedDashboard('enlaces')}>
            <div className="triangle"></div>
          </div>
          <div className="label-vecindades">VECINDADES</div>
          <div className="vecindades">
            {enlaces.map((dt: any, index: number) => {
              const [fromCountry] = dt.from.split('-');
              const [toCountry] = dt.to.split('-');
              if (fromCountry === toCountry) {
                return null;
              } else {
                // console.log(dt);
                return (
                  <div className="wrap-enlace" key={`enl-${index}`}>
                    <Enlace
                      from={dt.from}
                      fromStatus={dt.fromStatus}
                      fromIp={dt.fromIp}
                      to={dt.to}
                      toStatus={dt.toStatus}
                      status={dt.status}
                      label={dt.label}
                      problems={dt.problems}
                      speed={dt.speed}
                      siteUrl={siteUrl}
                      siteName={dt.site}
                    />
                  </div>
                );
              }
            })}
          </div>
        </div>
        <div className="sdwan">
          <div className="title">Cloud SDWAN</div>
          <div className="action-button" onClick={() => setSelectedDashboard('cloud')}>
            <div className="triangle"></div>
          </div>
          <div className="label-vecindades">ESTADO Y USO DE RECURSOS</div>
          <div className="content-sdwan">
            <SdwanCard title={'AWS'} keyCloud={'AWS'} image={AWS} statusCloud={statusCloud} />
            <SdwanCard title={'Google cloud'} keyCloud={'GCP'} image={GCP} statusCloud={statusCloud} />
            <SdwanCard title={'Azure'} keyCloud={'AZURE'} image={AZURE} statusCloud={statusCloud} />
            {/* <SdwanCard title={'Oracle'} image={ORACLE} statusCloud={statusCloud} /> */}
          </div>
        </div>
        <div className="info">
          <div className="title">Datacenter</div>
          <div className="action-button" onClick={() => setSelectedDashboard('general-status')}>
            <div className="triangle"></div>
          </div>
          <div className="label-vecindades">ESTADO GENERAL</div>
          <div className="info-content">{getInfo()}</div>
        </div>
      </>
    );
  }

  function getEnlaces(enlaces: any) {
    const alertRows = enlacesProblems.map((enlaceItem: any) => {
      return {
        type: (
          <div
            className="icon-status"
            style={{ backgroundColor: getStatusColorByType(enlaceItem.service_state) }}
          ></div>
        ),
        host: (
          <a href={`${buildHostUrl(enlaceItem.host, enlaceItem.site, siteUrl)}`} target="_blank" rel="noreferrer">
            {enlaceItem.host}
          </a>
        ),
        ip: enlaceItem.ip,
        service: enlaceItem.service_description,
        hour: enlaceItem.svc_state_age,
      };
    });
    const data = {
      columns: [
        {
          label: 'TIPO',
          field: 'type',
          sort: 'asc',
          width: 80,
        },
        {
          label: 'HOST',
          field: 'host',
          sort: 'asc',
          width: 270,
        },
        {
          label: 'IP',
          field: 'ip',
          sort: 'asc',
          width: 150,
        },
        {
          label: 'SERVICIO',
          field: 'service',
          sort: 'asc',
          width: 100,
        },
        {
          label: 'HORA',
          field: 'hour',
          sort: 'asc',
          width: 100,
        },
      ],

      rows: alertRows,
    };
    return (
      <>
        <div className="main-enlaces">
          <div className="title-main-enlaces">
            <div className="label-title-main-enlaces">Red internacional</div>
            <div className="action-title-main-enlaces" onClick={() => setSelectedDashboard('main')}>
              Volver
            </div>
          </div>
          <div className="wrap-enlaces-main-enlaces">
            <div className="enlaces">
              <div className="label-vecindades">VECINDADES</div>
              <div className="vecindades">
                {enlaces &&
                  enlaces.map((dt: any, index: number) => {
                    const [fromCountry] = dt.from.split('-');
                    const [toCountry] = dt.to.split('-');
                    if (fromCountry === toCountry) {
                      return null;
                    } else {
                      return (
                        <div className="wrap-enlace" key={`enl-${index}`}>
                          <Enlace
                            from={dt.from}
                            fromStatus={dt.fromStatus}
                            to={dt.to}
                            toStatus={dt.toStatus}
                            status={dt.status}
                            label={dt.label}
                            problems={dt.problems}
                            speed={dt.speed}
                            siteUrl={siteUrl}
                            siteName={dt.site}
                            fromIp={dt.fromIp}
                          />
                        </div>
                      );
                    }
                  })}
              </div>
            </div>
            <div className="graphic-enlaces">
              <div className="title-graphic-enlaces">DIAGRAMA DE RED INTERNACIONAL</div>
              <div className="body-graphic-enlaces">
                <Network arraytoDraw={arraytoDraw} siteUrl={siteUrl} />
              </div>
            </div>
          </div>
        </div>
        <div className="active-alerts">
          <div className="title-active-alerts">ALERTAS ACTIVAS</div>
          <div className="body-active-alerts">
            <TableActiveAlerts data={data} />
          </div>
        </div>
      </>
    );
  }

  useEffect(() => {
    const alertRows: any [] = [];
    const cloudObjectStatus: any = {
      CHILE: {
        AWS: 0,
        GCP: 0,
        AZURE: 0,
      },
    };
    for (const ht in servicesCloud) {
      if (Object.prototype.hasOwnProperty.call(servicesCloud, ht)) {
        const htServiceCloudArray = servicesCloud[ht];
        htServiceCloudArray.forEach((cloudItem: any) => {
          let srvDescription = cloudItem.description.toLowerCase();
          // termino = termino.toLowerCase();

          if (cloudItem.state !== 0) {
            if (srvDescription.includes('gcp')) {
              cloudObjectStatus[cloudItem.country]['GCP'] = 1;
            }
            if (srvDescription.includes('aws')) {
              cloudObjectStatus[cloudItem.country]['AWS'] = 1;
            }
            if (srvDescription.includes('azure')) {
              cloudObjectStatus[cloudItem.country]['AZURE'] = 1;
            }
            // console.log(cloudItem);
            const currentDate = new Date(cloudItem.last_state_change * 1000);
            let year = currentDate.getFullYear();
            let month = currentDate.getMonth() + 1;
            let day = currentDate.getDate();
            let hours = currentDate.getHours();
            let minutes = currentDate.getMinutes();
            let seconds = currentDate.getSeconds();
            let formattedDate = year + '-' + (month < 10 ? '0' : '') + month + '-' + (day < 10 ? '0' : '') + day;
            let formattedTime =
              (hours < 10 ? '0' : '') +
              hours +
              ':' +
              (minutes < 10 ? '0' : '') +
              minutes +
              ':' +
              (seconds < 10 ? '0' : '') +
              seconds;

            alertRows.push({
              type: (
                <div className="icon-status" style={{ backgroundColor: getStatusColorByNumber(cloudItem.state) }}></div>
              ),
              host: (
                <a
                  href={`${buildHostUrl(cloudItem.host_name, cloudItem.site, siteUrl)}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {cloudItem.host_name}
                </a>
              ),
              ip: cloudItem.address,
              service: cloudItem.description,
              hour: `${formattedDate} ${formattedTime}`,
              time: cloudItem.last_state_change,
            });
          }
        });
      }
    }
    setAlertRowsToGraphic(alertRows);
    setStatusCloud(cloudObjectStatus);
  }, [servicesCloud]);

  function getCloud() {
    const alertReal = alertRowsToGraphic.sort(compare);
    const data = {
      columns: [
        {
          label: 'TIPO',
          field: 'type',
          sort: 'asc',
          width: 80,
        },
        {
          label: 'HOST',
          field: 'host',
          sort: 'asc',
          width: 270,
        },
        {
          label: 'IP',
          field: 'ip',
          sort: 'asc',
          width: 150,
        },
        {
          label: 'SERVICIO',
          field: 'service',
          sort: 'asc',
          width: 100,
        },
        {
          label: 'HORA',
          field: 'hour',
          sort: 'asc',
          width: 100,
        },
      ],

      rows: alertReal,
    };
    return (
      <>
        <div className="info-sdwan">
          <div className="title">
            <div className="label-title-info">Cloud SDWAN</div>
            <div className="action-title-info" onClick={() => setSelectedDashboard('main')}>
              Volver
            </div>
          </div>
          <div className="label-vecindades">ESTADO GENERAL</div>
          <div className="content-sdwan">
            <SdwanCard title={'AWS'} keyCloud={'AWS'} image={AWS} statusCloud={statusCloud} />
            <SdwanCard title={'Google cloud'} keyCloud={'GCP'} image={GCP} statusCloud={statusCloud} />
            <SdwanCard title={'Azure'} keyCloud={'AZURE'} image={AZURE} statusCloud={statusCloud} />
            {/* <SdwanCard title={'Oracle'} image={ORACLE} statusCloud={statusCloud} /> */}
          </div>
        </div>
        <div className="details-sdwan"></div>
        <div className="active-alerts generalStatus">
          <div className="title-active-alerts">ALERTAS ACTIVAS</div>
          <div className="body-active-alerts">
            <TableActiveAlerts data={data} />
          </div>
        </div>
      </>
    );
  }

  function getGeneralStatus(enlaces: any) {
    const alertRows = allProblems.map((item: any) => {
      // console.log(item);

      // let host, site, ip, hour, service;
      let hour;
      let host = item.host ? item.host : item.host_name;
      let site = item.site;
      let ip = item.ip ? item.ip : item.address;
      let service = item.service_description ? item.service_description : item.description;
      let color = item.service_state ? getStatusColorByType(item.service_state) : getStatusColorByNumber(item.state);
      if (item.svc_state_age) {
        hour = item.svc_state_age;
      } else {
        // let hour = item.svc_state_age ? item.svc_state_age : item.last_state_change;
        const currentDate = new Date(item.last_state_change * 1000);
        let year = currentDate.getFullYear();
        let month = currentDate.getMonth() + 1;
        let day = currentDate.getDate();
        let hours = currentDate.getHours();
        let minutes = currentDate.getMinutes();
        let seconds = currentDate.getSeconds();
        let formattedDate = year + '-' + (month < 10 ? '0' : '') + month + '-' + (day < 10 ? '0' : '') + day;
        let formattedTime =
          (hours < 10 ? '0' : '') +
          hours +
          ':' +
          (minutes < 10 ? '0' : '') +
          minutes +
          ':' +
          (seconds < 10 ? '0' : '') +
          seconds;
        hour = `${formattedDate} ${formattedTime}`;
      }
      return {
        type: <div className="icon-status" style={{ backgroundColor: color }}></div>,
        host: (
          <a href={`${buildHostUrl(host, site, siteUrl)}`} target="_blank" rel="noreferrer">
            {host}
          </a>
        ),
        ip: ip,
        service: service,
        hour: hour,
      };
    });
    const data = {
      columns: [
        {
          label: 'TIPO',
          field: 'type',
          sort: 'asc',
          width: 80,
        },
        {
          label: 'HOST',
          field: 'host',
          sort: 'asc',
          width: 200,
        },
        {
          label: 'IP',
          field: 'ip',
          sort: 'asc',
          width: 150,
        },
        {
          label: 'SERVICIO',
          field: 'service',
          sort: 'asc',
          width: 400,
        },
        {
          label: 'HORA',
          field: 'hour',
          sort: 'asc',
          width: 100,
        },
      ],

      rows: alertRows,
    };
    return (
      <>
        <div className="info">
          <div className="title">
            <div className="label-title-info">Datacenter</div>
            <div className="action-title-info" onClick={() => setSelectedDashboard('main')}>
              Volver
            </div>
          </div>
          <div className="label-vecindades">ESTADO GENERAL</div>
          <div className="info-content">{getInfo()}</div>
        </div>
        <div className="active-alerts generalStatus">
          <div className="title-active-alerts">ALERTAS ACTIVAS</div>
          <div className="body-active-alerts">
            <TableActiveAlerts data={data} />
          </div>
        </div>
      </>
    );
  }
  function getDashboard(type: any, enlaces: any) {
    if (type === 'main') {
      return getMain(enlaces);
    } else if (type === 'enlaces') {
      return getEnlaces(enlaces);
    } else if (type === 'general-status') {
      return getGeneralStatus(enlaces);
    } else if (type === 'cloud') {
      return getCloud();
    } else {
      return getMain(enlaces);
    }
  }
  // console.log(servicesCloud);

  return <section className="datacenter">{getDashboard(selectedDashboard, dtEnlaces)}</section>;
};

export default DatacenterPanel;
