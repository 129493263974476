const buildHostUrl = (hostname, siteName, siteUrl) => {
  return (
    siteUrl +
    '/' +
    siteName +
    '/check_mk/index.py?start_url=' +
    encodeURIComponent('/' + siteName + '/check_mk/view.py?host=' + hostname + '&site=' + siteName + '&view_name=host')
  );
};

export { buildHostUrl };
