import React, { useState, useEffect } from 'react';
import Select from 'react-select';

import CardInfoRedes from 'components/CardInfoRedes/CardInfoRedes';
import Sidebar from '../../components/Sidebar/Sidebar';
import GeographicMapChile from 'components/GeographicMap/GeographicMap';
import AlertByDevice from 'components/AlertByDevice/AlertByDevice';
//@ts-ignore
import { getHostGroupsByTag, getProviderResumeFromDatacenters } from '../../api/datasource.js';

import { getStores, getRegionStatusByStores } from '../../api/store';

import { getStatusColorByThreshold, getStatusColorByNumber } from '../../utils/color';
import CustomTooltip from 'components/Tooltip/Tooltip';

import './NetworkPanel.css';

type Props = {
  services: any;
  hosts: any;
  infoDatacenter: any;
};
const regionOptions = [
  { value: 'TAR', label: 'Tarapacá' },
  { value: 'ANT', label: 'Antofagasta' },
  { value: 'ATA', label: 'Atacama' },
  { value: 'COQ', label: 'Coquimbo' },
  { value: 'VAL', label: 'Valparaíso' },
  { value: 'SGO', label: 'Metropolitana' },
  { value: 'OHI', label: 'O’Higgins' },
  { value: 'MAU', label: 'Maule' },
  { value: 'BIO', label: 'Bio-Bio' },
  { value: 'ARA', label: 'Araucanía' },
  { value: 'LAG', label: 'Los Lagos' },
  { value: 'ARI', label: 'Arica y Parinacota' },
];
const storeOptions = [
  { value: 'PARIS', label: 'PARIS' },
  { value: 'JUMBO', label: 'JUMBO' },
  { value: 'JOHNSON', label: 'JOHNSON' },
  { value: 'SISA', label: 'SISA' },
  { value: 'CD', label: 'CD' },
  { value: 'BO', label: 'BO' },
  { value: 'ADM', label: 'ADM' },
];

const NetworkPanel: React.FC<Props> = ({ hosts = [], services = [], infoDatacenter = [] }) => {
  const [countryData, setCountryData] = useState<any[]>([]);
  const [hoverStore, setHoverStore] = useState<any>(null);
  const [showAllProp, setShowAllProp] = useState(false);
  //@ts-ignore
  const [selectedCountries, setSelectedCountries] = useState<any[]>([]);
  const [arrayRegionStatus, setArrayRegionStatus] = useState<any[]>([]);
  const [stores, setStores] = useState<any[]>([]);
  const [storesWithProblems, setStoresWithProblems] = useState<any[]>([]);
  const [scale, setScale] = useState<any>(1.75);
  // const [scale, setScale] = useState<any>(1);
  const [filterRegion, setFilterRegion] = useState<any[]>([]);
  const [filterStore, setFilterStore] = useState<any[]>([]);
  //@ts-ignore
  useEffect(() => {
    const countryDataTmp = [
      {
        country: 'chile',
        name: 'Chile',
        generalStatus: -1,
        selected: true,
        datacenters: {
          total: 0,
          problems: 0,
        },
        devices: {
          total: 0,
          problems: 0,
        },
        services: {
          total: 0,
          problems: 0,
        },
        links: {
          total: 0,
          problems: 0,
        },
        stores: {
          total: 0,
          problems: 0,
        },
      },
      {
        country: 'argentina',
        name: 'argentina',
        generalStatus: -1,
        datacenters: {
          total: 0,
          problems: 0,
        },
        devices: {
          total: 0,
          problems: 0,
        },
        services: {
          total: 0,
          problems: 0,
        },
        links: {
          total: 0,
          problems: 0,
        },
        stores: {
          total: 0,
          problems: 0,
        },
      },
      {
        country: 'brasil',
        name: 'brasil',
        generalStatus: -1,
        datacenters: {
          total: 0,
          problems: 0,
        },
        devices: {
          total: 0,
          problems: 0,
        },
        services: {
          total: 0,
          problems: 0,
        },
        links: {
          total: 0,
          problems: 0,
        },
        stores: {
          total: 0,
          problems: 0,
        },
      },
      {
        country: 'peru',
        name: 'peru',
        generalStatus: -1,
        datacenters: {
          total: 0,
          problems: 0,
        },
        devices: {
          total: 0,
          problems: 0,
        },
        services: {
          total: 0,
          problems: 0,
        },
        links: {
          total: 0,
          problems: 0,
        },
        stores: {
          total: 0,
          problems: 0,
        },
      },
      {
        country: 'colombia',
        name: 'colombia',
        generalStatus: -1,
        datacenters: {
          total: 0,
          problems: 0,
        },
        devices: {
          total: 0,
          problems: 0,
        },
        services: {
          total: 0,
          problems: 0,
        },
        links: {
          total: 0,
          problems: 0,
        },
        stores: {
          total: 0,
          problems: 0,
        },
      },
    ];
    const country = {
      name: 'chile',
    };
    const hostsFiltered = hosts.filter((host: any) => {
      return host.tags.SITE === country.name.toUpperCase();
    });

    const stores = getStores(hostsFiltered);
    const regions = getRegionStatusByStores(stores);
    let globalStatus = 0;
    regions.forEach((region: any) => {
      globalStatus = globalStatus < region.status ? region.status : globalStatus;
    });
    countryDataTmp[0].generalStatus = globalStatus;
  //@ts-ignore
    updateStoresWithProblems(stores, filterRegion, filterStore);
    setStores(stores);
    setArrayRegionStatus(regions);
    setCountryData(countryDataTmp);
    setSelectedCountries([countryDataTmp[0]]);
  }, [hosts, services]);

  function showTip(store: string) {
    setHoverStore(store);
  }

  function hideTip() {
    setHoverStore(null);
  }

  function getSuppliers() {
    const country = {
      name: 'chile',
    };
    const providers = getProviderResumeFromDatacenters(
      infoDatacenter.find((datacenter: any) => datacenter.name === country.name.toUpperCase())
    );
    return Object.values(providers).map((supplier: any, index: any) => (
      <div key={`supplier-${index}`} className="each-supplier">
        <CardInfoRedes
          name={supplier.name.toUpperCase()}
          type={'supplier'}
          label={'DOWN / UP'}
          info={`${supplier.problems} / ${supplier.total - supplier.problems}`}
          problems={supplier.problems}
          status={supplier.status}
          percentage={supplier.percentage}
        />
      </div>
    ));
  }

  function setCountry(country: string) {
    console.log(`Select country ${country}`);
    // const countryDataTmp = countryData.map((countryItem: any) => {
    //   if (countryItem.country === country) {
    //     countryItem.selected = true;
    //   } else {
    //     countryItem.selected = false;
    //   }
    //   return countryItem;
    // });
    // const selected = countryDataTmp.filter((countryItem: any) => countryItem.selected);
    // setCountryData(countryDataTmp);
    // setSelectedCountries(selected);
  }

  function resizeSection(type: string) {
    let newScale = scale;
    if (type === 'in') {
      newScale += 0.5;
    } else if (type === 'out') {
      newScale -= 0.5;
    }
    setScale(newScale);
  }

  function getAlertsByDevice() {
    const validTypes = ['ROUTER', 'SWITCH', 'AP', 'FORTINET'];

    const deviceTypes = getHostGroupsByTag(hosts, 'TIPO_DE_DISPOSITIVO');
    const arrayDeviceByType = deviceTypes.filter((deviceType: any) => {
      return validTypes.includes(deviceType.key);
    });

    return arrayDeviceByType.map((deviceGroup: any, index: any) => (
      <AlertByDevice
        key={`${index}-device-type`}
        type={deviceGroup.key}
        hostDowntype={deviceGroup.hostProblems}
        hostUptype={deviceGroup.hostTotal - deviceGroup.hostProblems}
        servicesProblemstype={
          deviceGroup.serviceProblems - deviceGroup.hostProblems > 0
            ? deviceGroup.serviceProblems - deviceGroup.hostProblems
            : 0
        }
      />
    ));
  }

  function udpateFilterRegion(filterRegion: any) {
    setFilterRegion(filterRegion);
    //@ts-ignore
    updateStoresWithProblems(stores, filterRegion, filterStore);
  }

  function udpateFilterStore(filterStore: any) {
    setFilterStore(filterStore);
    //@ts-ignore
    updateStoresWithProblems(stores, filterRegion, filterStore);
  }

  function updateStoresWithProblems(stores: any, filterRegion = [], filterStore = []) {
    let storesWithProblems = stores.filter((store: any) => {
      return store.criticity > 0;
    });
    storesWithProblems.sort((a: any, b: any) => {
      return b.criticity - a.criticity;
    });
    storesWithProblems.sort((a: any, b: any) => {
      return b.state - a.state;
    });
    if (filterRegion.length > 0) {
      storesWithProblems = storesWithProblems.filter((store: any) => {
        return filterRegion.some((region: any) => {
          return region.value === store.region;
        });
      });
    }
    if (filterStore.length > 0) {
      storesWithProblems = storesWithProblems.filter((store: any) => {
        return filterStore.some((storeType: any) => {
          return storeType.value === store.name;
        });
      });
    }
    setStoresWithProblems(storesWithProblems);
  }

  function getStoreProblemsByFilter() {
    let arrayItems: any[] = [];
    let auxCount = 0;
    storesWithProblems.forEach((store: any, index: any) => {
      if (showAllProp) {
        arrayItems.push(
          <tr className="item-row" key={`${index}-item`}>
            <td>{store.region}</td>
            <td>
              {store.name} | {store.code}
            </td>
            <td style={{ color: getStatusColorByThreshold(store.saturation, [75, 90]) }}>
              {store.saturation.toFixed(1)}%
            </td>
            <td>
              {store.hostTotal - store.hostProblems}/{store.hostTotal}
            </td>
            <td style={{ color: getStatusColorByNumber(store.state) }}>
              <div
                className="icon-status"
                style={{ backgroundColor: getStatusColorByNumber(store.state) }}
                onMouseEnter={() => showTip(store.code)}
                onMouseLeave={() => hideTip()}
              >
                <CustomTooltip
                  content={`Dispositivos: ${store.devices.total - store.devices.problems} / ${
                    store.devices.total
                  }, Routers: ${store.routers.total - store.routers.problems} / ${store.routers.total}, Switchs: ${
                    store.switchs.total - store.switchs.problems
                  } / ${store.switchs.total}`}
                  direction={'left'}
                  show={store.code === hoverStore ? true : false}
                />
              </div>
            </td>
          </tr>
        );
      } else {
        if (store.state > 0 || auxCount < 10) {
          console.log("STATE:", store.state);
          console.log(auxCount);
          
          arrayItems.push(
            <tr className="item-row" key={`${index}-item`}>
              <td>{store.region}</td>
              <td>
                {store.name} | {store.code}
              </td>
              <td style={{ color: getStatusColorByThreshold(store.saturation, [75, 90]) }}>
                {store.saturation.toFixed(1)}%
              </td>
              <td>
                {store.hostTotal - store.hostProblems}/{store.hostTotal}
              </td>
              <td style={{ color: getStatusColorByNumber(store.state) }}>
                <div
                  className="icon-status"
                  style={{ backgroundColor: getStatusColorByNumber(store.state) }}
                  onMouseEnter={() => showTip(store.code)}
                  onMouseLeave={() => hideTip()}
                >
                  <CustomTooltip
                    content={`Dispositivos: ${store.devices.total - store.devices.problems} / ${
                      store.devices.total
                    }, Routers: ${store.routers.total - store.routers.problems} / ${store.routers.total}, Switchs: ${
                      store.switchs.total - store.switchs.problems
                    } / ${store.switchs.total}`}
                    direction={'left'}
                    show={store.code === hoverStore ? true : false}
                  />
                </div>
              </td>
            </tr>
          );
          auxCount++;
        }
      }
    });
    return arrayItems;
  }

  function showAll() {
    console.log(!showAllProp);
    
    setShowAllProp(!showAllProp);
  }

  return (
    <section className="network-panel-cmpt">
      <div className="sidebar-network-panel">
        <Sidebar countryInfo={countryData} setCountryFn={setCountry} />
      </div>
      <div className="supplier-wrap">
        <div className="title-supplier-wrap">{'Proveedores'}</div>
        {getSuppliers()}
      </div>
      <div className="geomap-wrap">
        <div className="title-geomap">{'Geomap / Tiendas caidas'}</div>
        <div className="graphic-geomap">
          <div className="actions">
            <div className="click" onClick={() => resizeSection('in')}>
              +
            </div>
            <div className="click" onClick={() => resizeSection('out')}>
              -
            </div>
          </div>
          <div className="graphic-wrap">
            <GeographicMapChile
              chooseRegion={() => console.log('test')}
              regiones={arrayRegionStatus}
              info={''}
              regionNumber={[]}
              region={''}
              scale={scale}
            />
          </div>
        </div>
      </div>
      <div className="active-alerts-wrap">
        <div className="title-active-alerts-wrap">
          <span>Tiendas / Alarmas activas</span>
          <div className="filters">
            <div className="filter">
              <span className="filter-title">Región</span>
              <div className="filter-input">
                <Select
                  options={regionOptions}
                  closeMenuOnSelect={false}
                  isClearable
                  isMulti
                  onChange={udpateFilterRegion}
                />
              </div>
            </div>
            <div className="filter">
              <span className="filter-title">Tipo de tienda</span>
              <div className="filter-input">
                <Select
                  options={storeOptions}
                  closeMenuOnSelect={false}
                  isClearable
                  isMulti
                  onChange={udpateFilterStore}
                />
              </div>
            </div>
            <div className="show-all">
              <div className="filter-title">Todos</div>
              <div className='filter-checkbox'>
                <input type="checkbox" onChange={showAll} checked={showAllProp} />
              </div>
            </div>
          </div>
        </div>
        <div className="active-problems">
          <table className="store-problems-table">
            <thead>
              <tr>
                <th>Región</th>
                <th>Tienda</th>
                <th>Saturación</th>
                <th>Disponibilidad</th>
                {/* <th>Routers problems</th> */}
                {/* <th>Switchs problems</th> */}
                {/* <th>Saturación</th>
                <th>Problemas</th> */}
                <th></th>
              </tr>
            </thead>
            <tbody>{getStoreProblemsByFilter()}</tbody>
          </table>
        </div>
      </div>
      <div className="network-status-wrap">
        <div className="title-network-status-wrap">{'Estado de red'}</div>
        <div className="devices-network-status-wrap">{getAlertsByDevice()}</div>
        <div className="active-alerts-network-status-wrap"></div>
      </div>
    </section>
  );
};

export default NetworkPanel;
