import React, { useState, useEffect } from 'react';

import Sidebar from '../../components/Sidebar/Sidebar';
import Card from 'components/Card/Card';
import CardTitle from 'components/Card/CardTitle';
import CardStates from 'components/Card/CardStates';
import CardDropdown from 'components/CardDropdown/CardDropdown';
import NocDetailsStore from 'views/NocDetails/NocDetailsStore';
import NocDetailsDatacenter from 'views/NocDetails/NocDetailsDatacenter';
import NocDetailsCD from 'views/NocDetails/NocDetailsCD';

import { getStores } from '../../api/store';

import './NOCPanel.css';

type Props = {
  hostsServices: any;
  hosts: any;
  infoDatacenter: any;
  onChange: any;
};

const NOCPanel: React.FC<Props> = ({ hosts = [], hostsServices = {}, infoDatacenter, onChange }) => {
  const [selectedDashboard, setSelectedDashboard] = useState('main');
  const [countryData, setCountryData] = useState<any[]>([]);

  //   const [countryData, setCountryData] = useState<any[]>([]);
  //   const [dataCenterInfo, setDataCenterInfo] = useState<any>({});
  //   const [serviceClassification, setServiceClassification] = useState<any>({});
  const [selectedCountries, setSelectedCountries] = useState<any[]>([]);

  useEffect(() => {
    const countryDataTmp = [
      {
        country: 'chile',
        name: 'Chile',
        generalStatus: 'WARNING',
        selected: true,
        datacenters: {
          total: 0,
          problems: 0,
        },
        devices: {
          total: 0,
          problems: 0,
        },
        services: {
          total: 0,
          problems: 0,
        },
        links: {
          total: 0,
          problems: 0,
        },
        stores: {
          total: 0,
          problems: 0,
        },
      },
      {
        country: 'argentina',
        name: 'Argentina',
        generalStatus: 'WARNING',
        datacenters: {
          total: 0,
          problems: 0,
        },
        devices: {
          total: 0,
          problems: 0,
        },
        services: {
          total: 0,
          problems: 0,
        },
        links: {
          total: 0,
          problems: 0,
        },
        stores: {
          total: 0,
          problems: 0,
        },
      },
      {
        country: 'brasil',
        name: 'Brasil',
        generalStatus: 'WARNING',
        datacenters: {
          total: 0,
          problems: 0,
        },
        devices: {
          total: 0,
          problems: 0,
        },
        services: {
          total: 0,
          problems: 0,
        },
        links: {
          total: 0,
          problems: 0,
        },
        stores: {
          total: 0,
          problems: 0,
        },
      },
      {
        country: 'peru',
        name: 'Perú',
        generalStatus: 'OK',
        datacenters: {
          total: 0,
          problems: 0,
        },
        devices: {
          total: 0,
          problems: 0,
        },
        services: {
          total: 0,
          problems: 0,
        },
        links: {
          total: 0,
          problems: 0,
        },
        stores: {
          total: 0,
          problems: 0,
        },
      },
      {
        country: 'colombia',
        name: 'Colombia',
        generalStatus: 'WARNING',
        datacenters: {
          total: 0,
          problems: 0,
        },
        devices: {
          total: 0,
          problems: 0,
        },
        services: {
          total: 0,
          problems: 0,
        },
        links: {
          total: 0,
          problems: 0,
        },
        stores: {
          total: 0,
          problems: 0,
        },
      },
    ];
    setCountryData(countryDataTmp);
    setSelectedCountries([countryDataTmp[0]]);
  }, [hosts, hostsServices, infoDatacenter]);

  function setCountry(country: string) {
    const countryDataTmp = countryData.map((countryItem) => {
      if (countryItem.country === country) {
        countryItem.selected = !countryItem.selected;
      }
      return countryItem;
    });
    const selected = countryDataTmp.filter((countryItem) => countryItem.selected);
    // console.log(countryDataTmp);
    setCountryData(countryDataTmp);
    setSelectedCountries(selected);
  }
  function getDashboard(type: any) {
    if (type === 'main') {
      return getMain();
    } else if (type === 'tiendas') {
      return <NocDetailsStore hosts={hosts} country={countryData} onBack={() => setSelectedDashboard('main')} selectedNames={selectedCountries} />;
    } else if (type === 'enlaces_') {
      return <NocDetailsDatacenter hosts={hosts} country={countryData} onBack={() => setSelectedDashboard('main')} />;
    } else if (type === 'dispositivos_') {
      return <NocDetailsDatacenter hosts={hosts} country={countryData} onBack={() => setSelectedDashboard('main')} />;
    } else if (type === 'datacenter') {
      return <NocDetailsDatacenter hosts={hosts} country={countryData} onBack={() => setSelectedDashboard('main')} />;
    } else if (type === 'cd') {
      return <NocDetailsCD hosts={hosts} country={countryData} onBack={() => setSelectedDashboard('main')} />;
    } else {
      return getMain();
    }
  }
  function getResumeItems() {
    return selectedCountries.map((country: any, index: any) => {
      const hostsFiltered = hosts.filter((host: any) => {
        return host.tags.SITE === country.country.toUpperCase();
      });

      const allStores = getStores(hostsFiltered);
      const stores = {
        total: 0,
        down: [],
        withProblems: [],
        withUptimeProblems: [],
      };
      const cd = {
        total: 0,
        down: [],
        withProblems: [],
        withUptimeProblems: [],
      };
      allStores.forEach((store: any) => {
        let source: any = stores;
        if (store.name === 'ADM' || store.name === 'BODEGA' || store.name === 'BO' || store.name === 'CD') {
          source = cd;
        }
        if (store.state === 2) {
          source.down.push(store);
        }
        if (store.criticity > 0) {
          source.withProblems.push(store);
        }
        if (store.hasUptimeProblems) {
          source.withUptimeProblems.push(store);
        }
        source.total += 1;
      });

      const resumeStores = {
        name: 'Datacenter',
        link: 'tiendas',
        status: stores.total === 0 ? 'UNKNOWN' : stores.down.length > 0 ? 'CRITICAL' : 'OK',
        info: `${stores.withProblems.length}`,
        states: [
          { value: `${stores.down.length}/${stores.total - stores.down.length}`, name: 'down / up' },
          {
            value: `${stores.withUptimeProblems.length}/${stores.total - stores.withUptimeProblems.length}`,
            name: 'uptime',
          },
        ],
      };

      const resumeCD = {
        name: 'CD/BO/ADM',
        link: 'cd',
        status: cd.total === 0 ? 'UNKNOWN' : cd.down.length > 0 ? 'CRITICAL' : 'OK',
        info: `${cd.withProblems.length}`,
        states: [
          { value: `${cd.down.length}/${cd.total - cd.down.length}`, name: 'down / up' },
          {
            value: `${cd.withUptimeProblems.length}/${cd.total - cd.withUptimeProblems.length}`,
            name: 'uptime',
          },
        ],
      };
      const datacenters: any = {
        total: 0,
        down: [],
        withProblems: 0,
      };
      const links = {
        total: 0,
        down: 0,
        withProblems: 0,
      };
      infoDatacenter.forEach((resume: any) => {
        if (resume.name === country.country.toUpperCase()) {
          resume.arrayDatacenter.forEach((datacenter: any) => {
            if (datacenter.status > 0) {
              if (datacenter.status === 2) {
                datacenters.down.push(datacenter);
              }
              datacenter.withProblems += 1;
            }
            datacenters.total += 1;
            links.total += datacenter.enlaces.total;
            links.down += datacenter.enlaces.total - datacenter.enlaces.ok;
            links.withProblems += datacenter.enlaces.total - datacenter.enlaces.ok;
          });
        }
      });
      const resumeDatacenters = {
        name: 'Datacenter',
        status: datacenters.total === 0 ? 'UNKNOWN' : datacenters.down.length > 0 ? 'CRITICAL' : 'OK',
        info: '',
        states: [
          { value: `${datacenters.down.length} / ${datacenters.total - datacenters.down.length}`, name: 'down / up' },
        ],
      };

      const resumeEnlaces = {
        name: 'Enlaces',
        link: 'enlaces',
        status: links.total === 0 ? 'UNKNOWN' : links.down > 0 ? 'CRITICAL' : 'OK',
        info: '',
        states: [{ value: `${links.down} / ${links.total - links.down}`, name: 'down / up' }],
      };

      let totalProblems = 0;
      hostsFiltered.forEach((host: any) => {
        totalProblems += host.serviceProblems;
      });
      const devices = {
        total: 0,
        down: 0,
        withProblems: 0,
      };
      hostsFiltered.forEach((host: any) => {
        if (host.state > 0) {
          if (host.state === 2) {
            devices.down += 1;
          }
          devices.withProblems += 1;
        }
        devices.total += 1;
      });
      const resumeDevices = {
        name: 'Dispositivos',
        link: 'dispositivos',
        status:
          devices.total === 0
            ? 'UNKNOWN'
            : devices.down > devices.total * 0.5
            ? 'CRITICAL'
            : devices.down > devices.total * 0.2
            ? 'WARNING'
            : 'OK',
        info: '',
        states: [{ value: `${devices.down} / ${devices.total - devices.down}`, name: 'down / up' }],
      };

      return (
        <section className="item-noc" key={`noc-panel-${index}`}>
          <div className="title-wrap">
            <div className="title">{country.name}</div>
            <div className="title-alerts">
              <span style={{ fontSize: '20px', fontWeight: 'bold' }}>{totalProblems} </span>
              ALERTAS ACTIVAS TOTALES
            </div>
          </div>
          <div className="label-message">RESUMEN | ALERTAS ACTIVAS</div>
          <div className="resume-row" key={`dt-each-${index}`}>
            <div className="resume-item" onClick={() => setSelectedDashboard(resumeStores.link)}>
              <Card status={resumeStores.status}>
                <CardTitle title="Tiendas" icon="tiendas_noc" info={resumeStores.info} />
                <CardStates states={resumeStores.states} />
              </Card>
            </div>
            <div className="resume-item" onClick={() => setSelectedDashboard(resumeCD.link)}>
              <Card status={resumeCD.status}>
                <CardTitle title={resumeCD.name} icon="cd_bo_adm" info={resumeCD.info} />
                <CardStates states={resumeCD.states} />
              </Card>
            </div>
            <div className="resume-item" onClick={() => onChange('datacenter')}>
              <Card status={resumeDatacenters.status}>
                <CardTitle title="Datacenter" icon="enlaces_noc" info={resumeDatacenters.info} />
                <CardStates states={resumeDatacenters.states} />
              </Card>
            </div>
            <div className="resume-item" onClick={() => onChange('datacenter')}>
              <Card status={resumeEnlaces.status}>
                <CardTitle title={resumeEnlaces.name} icon="dispositivos_noc" info={resumeEnlaces.info} />
                <CardStates states={resumeEnlaces.states} />
              </Card>
            </div>
            <div className="resume-item" onClick={() => setSelectedDashboard(resumeDevices.link)}>
              <Card status={resumeDevices.status}>
                <CardTitle title={resumeDevices.name} icon="datacenter_noc" info={resumeDevices.info} />
                <CardStates states={resumeDevices.states} />
              </Card>
            </div>
          </div>
          <div className="extra-info">
            <CardDropdown label={'VISTA DE ANALISIS'}>
              <div></div>
            </CardDropdown>
          </div>
        </section>
      );
    });
  }
  function getMain() {
    return <div className="resume">{getResumeItems()}</div>;
  }
  return (
    <section className="noc">
      <div className="noc-sidebar">
        <Sidebar countryInfo={countryData} setCountryFn={setCountry} />
      </div>
      <div className="noc-main">{getDashboard(selectedDashboard)}</div>
    </section>
  );
};

export default NOCPanel;
