import React, { ReactNode, useRef, useEffect } from 'react';
import { getStatusColorByType, getStatusHoverColorByType } from '../../utils/color';

import './Card.css';

type Props = {
  status: String;
  children: ReactNode;
  secondary?: Boolean;
  actionCard?: any;
  selected?: Boolean;
  fillSelected?: Boolean;
};

const Card: React.FC<Props> = ({ status, children, secondary, actionCard, selected, fillSelected }) => {
  const type = '';
  let ref: any = useRef();

  function onClickCard() {
    if (actionCard) {
      actionCard();
    }
  }

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      if (ref.current) {
        const cardElement = ref.current;
        const colorHover = getStatusHoverColorByType(status);
        cardElement.addEventListener('mouseover', () => {
          if (!selected) {
            cardElement.style.backgroundColor = colorHover;
          }
        });
        cardElement.addEventListener('mouseout', () => {
          if (selected) {
            cardElement.style.backgroundColor = getStatusColorByType(status);
          } else {
            cardElement.style.backgroundColor = '';
          }
        });
        if (selected) {
          cardElement.style.backgroundColor = getStatusColorByType(status);
          cardElement.style.color = '#fff';
        } else {
          cardElement.style.backgroundColor = '';
          cardElement.style.color = 'rgb(92, 96, 106)';
        }
      }
    });
    observer.observe(ref.current);
    return () => ref.current && observer.unobserve(ref.current);
  }, [ref, status, selected]);

  return (
    <section
      className="dpa-card"
      style={{
        border: `2px solid ${getStatusColorByType(status)}`,
        flexDirection: secondary ? 'row' : 'column',
        //@ts-ignore
        alignItems: type === 'title' ? 'flex-start' : 'flex-end',
        // backgroundColor: selected ? getStatusColorByType(status) : 'none',
        // color: selected ? '#ffffff' : 'none',
      }}
      ref={ref}
      onClick={onClickCard}
    >
      {children}
    </section>
  );
};

export default Card;
