import React, { useEffect, useState, useRef } from 'react';
import Router from './Router';
import EnlaceConexion from 'components/EnlaceConexion/EnlaceConexion';
//@ts-ignore
import { buildHostUrl } from 'utils/common.js';

import './Network.css';
//@ts-ignore
const Network = ({ width = 875, height = 800, arraytoDraw, siteUrl }) => {
  const divRef = useRef(null);
  const [radius, setRadius] = useState(height / 2 - 50);
  const [centerX, setCenterX] = useState(width / 2);
  const [centerY, setCenterY] = useState(height / 2);
  const dictStatus = { 0: '#10d884', 1: 'yellow', 2: 'red' };
  const [sidePositions, setSidePositions] = useState<any []>([]);
  const [mainPositions, setMainPositions] = useState<any []>([]);
  const [sideRt, setSideRt] = useState<any []>([]);
  const [mainRt, setMainRt] = useState<any []>([]);
  // const [scale, setScale] = useState(0.2 / 1.2 + 0.15);
  const [scale, setScale] = useState(0.2 / 1.2 + 0.05);
  const [enlaces, setEnlaces] = useState<any []>([]);
  const [linesSection, setLinesSection] = useState<any>(null);
  const [showBoundCountries, setShowBoundCountries] = useState(true);
  const [showBoundMain, setShowBoundMain] = useState(true);

  useEffect(() => {
    const sideArray: any[] = [];
    const mainArray: any[] = [];
    const toFillEnlaces: any[] = [];
    arraytoDraw.forEach((rt: any) => {
      let inTmp = '';
      let outTmp = '';
      rt = rt.substring(0, rt.length - 1);
      let [type, name, status, enlacesIn, enlacesOut, enlaces] = rt.split(';');
      if (!enlaces) {
        enlaces = enlacesIn;
      } else {
        inTmp = enlacesIn;
        outTmp = enlacesOut;
      }
      let site = 'NREDES_CL2';
      if (enlaces) {
        const arrayEnlacesByDc = enlaces.split(',');
        arrayEnlacesByDc.forEach((enlaceDc: any) => {
          //@ts-ignore
          const [to, speed, status, siteName] = enlaceDc.split(/\|/);
          toFillEnlaces.push({ type, from: name, to, speed, status, inSpeed: inTmp, outSpeed: outTmp });
        });
      }
      const url_from_checkmk = buildHostUrl(name, site, siteUrl);
      if (type === 'main') {
        //@ts-ignore
        mainArray.push({ name, statusColor: dictStatus[status], siteUrl: url_from_checkmk });
      } else {
        //@ts-ignore
        sideArray.push({ name, statusColor: dictStatus[status], siteUrl: url_from_checkmk });
      }
    });
    setMainRt(mainArray);
    setSideRt(sideArray);
    const sPositions = [];
    for (let i = 0; i < sideArray.length; i++) {
      let deltaX = 0;
      let deltaY = 0;
      if (i === 0) {
        deltaY = 56;
      } else if (i === 1) {
        deltaX = 0;
        deltaY = -75;
      } else if (i === 2) {
        deltaX = 80;
        deltaY = -90;
      } else if (i === 3) {
        deltaX = 50;
        deltaY = -90;
      } else if (i === 4) {
        deltaX = 65;
        deltaY = 35;
      } else if (i === 5) {
        deltaX = 0;
        deltaY = 80;
      } else if (i === 6) {
        deltaX = -65;
        deltaY = 0;
      } else if (i === 7) {
        deltaX = -20;
        deltaY = 0;
      } else if (i === 8) {
        deltaX = 80;
        deltaY = 0;
      } else if (i === 9) {
        deltaX = 65;
        deltaY = 0;
      }
      const angle = (i / sideArray.length) * Math.PI * 2;
      const x = deltaX + centerX + Math.cos(angle) * radius;
      const y = deltaY + centerY + Math.sin(angle) * radius;
      sPositions.push({ x, y });
    }
    setSidePositions(sPositions);
    const mPositions = [];
    for (let i = 0; i < mainArray.length; i++) {
      let deltaX = 0;
      let deltaY = 0;
      if (i === 0) {
        deltaX = -60;
        deltaY = -65;
      } else if (i === 1) {
        deltaX = -10;
        deltaY = -65;
      }
      const angle = (i / mainArray.length) * Math.PI * 2;
      const x = deltaX + centerX + Math.cos(angle) * (radius / 4);
      const y = deltaY + centerY;
      mPositions.push({ x, y });
    }
    setMainPositions(mPositions);
    setEnlaces(toFillEnlaces);
  }, [radius, centerX, centerY, arraytoDraw]);

  useEffect(() => {
    const movableElement: any = document.getElementById('move-shape');
    let initialX: any, initialY: any;
    let isDragging = false;

    function handleMouseDown(event: any) {
      isDragging = true;
      initialX = event.clientX - movableElement.offsetLeft;
      initialY = event.clientY - movableElement.offsetTop;
      document.addEventListener('mousemove', handleMouseMove);
    }

    function handleMouseMove(event: any) {
      if (isDragging) {
        const newX = event.clientX - initialX;
        const newY = event.clientY - initialY;
        movableElement.style.left = newX + 'px';
        movableElement.style.top = newY + 'px';
      }
    }

    function handleMouseUp() {
      isDragging = false;
      document.removeEventListener('mousemove', handleMouseMove);
    }

    movableElement.addEventListener('mousedown', handleMouseDown);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      movableElement.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, [arraytoDraw]);

  function getSideComponents() {
    return sidePositions.map((pos, index) => (
      <Router
        name={sideRt[index]['name']}
        siteUrl={sideRt[index]['siteUrl']}
        statusColor={sideRt[index]['statusColor']}
        key={index}
        color={'33, 171, 235'}
        scale={scale}
        style={{
          position: 'absolute',
          left: pos.x - 10 - 155 + 'px',
          top: pos.y - 10 - 105 + 'px',
          // display: showBoundCountries ? 'inline-block' : 'none',
        }}
      />
    ));
  }
  function getMainComponents() {
    return mainPositions.map((pos, index) => (
      <Router
        name={mainRt[index]['name']}
        siteUrl={sideRt[index]['siteUrl']}
        statusColor={mainRt[index]['statusColor']}
        key={index}
        color={'85, 123, 139'}
        scale={scale}
        style={{
          position: 'absolute',
          left: pos.x - 10 - 155 + 'px',
          top: pos.y - 10 - 105 + 'px',
          display: showBoundMain ? 'inline-block' : 'none',
        }}
      />
    ));
  }

  function resizeSection(type: any) {
    let newScale = scale;
    let newCenterX = 0;
    let newCenterY = 0;
    let newRadius = 0;
    if (type === 'in') {
      newScale += 0.05;
      newCenterX = centerX * 1.2;
      newCenterY = centerY * 1.2;
      newRadius = radius * 1.2;
    } else if (type === 'out') {
      newScale -= 0.05;
      newCenterX = centerX / 1.2;
      newCenterY = centerY / 1.2;
      newRadius = radius / 1.2;
    }
    setScale(newScale);
    setCenterX(newCenterX);
    setCenterY(newCenterY);
    setRadius(newRadius);
  }

  useEffect(() => {
    if (divRef.current) {
      const cmptLinesSection = (
        <EnlaceConexion enlaces={enlaces} showBoundCountries={showBoundCountries} showBoundMain={showBoundMain} />
      );
      setLinesSection(cmptLinesSection);
    }
  }, [divRef, enlaces, arraytoDraw, showBoundMain, showBoundCountries]);

  function showOnlyCountries() {
    setShowBoundMain(!showBoundMain);
  }

  function showOnlyMain() {
    setShowBoundCountries(!showBoundCountries);
  }

  return (
    <section className="network-cmpt" style={{ width: '100%', height: '100%', position: 'relative' }}>
      <div className="flags">
        <div className="cont">
          <div className="line" style={{ border: '1px dashed #000000' }}></div>
          <div className="text">1 Gb</div>
        </div>
        <div className="cont">
          <div className="line" style={{ border: '1px dashed #fb34d3' }}></div>
          <div className="text">300 Mb</div>
        </div>
        <div className="cont">
          <div className="line" style={{ border: '1px dashed #39ff14' }}></div>
          <div className="text">120 Mb</div>
        </div>
        <div className="cont">
          <div className="line" style={{ border: '1px dashed #ffa55d' }}></div>
          <div className="text">100 Mb</div>
        </div>
      </div>
      <div className="actions">
        <div className="click" onClick={() => resizeSection('in')}>
          +
        </div>
        <div className="click" onClick={() => resizeSection('out')}>
          -
        </div>
        <div className="click" onClick={() => showOnlyCountries()}>
          Main
        </div>
        <div className="click" onClick={() => showOnlyMain()}>
          Side
        </div>
      </div>
      <div id="move-shape" ref={divRef}>
        {linesSection}
        {getSideComponents()}
        {getMainComponents()}
      </div>
    </section>
  );
};

export default Network;
