import { region_dictionary } from '../constants/country_map_info';

export function getStores(hosts: any[]): any[] {
  const stores: any = {};
  hosts.forEach((host: any) => {
    const store_key = host.tags.CODIGO_DE_TIENDA;
    if (store_key) {
      if (!stores[store_key]) {
        stores[store_key] = {
          code: host.tags.CODIGO_DE_TIENDA,
          name: host.tags.TIENDA,
          region: host.tags.region,
          saturation: 0,
          hosts: {
            withUptime: 0,
            withUptimeProblems: 0,
          },
          uptimeProblemsPercent: 0,
          devices: {
            total: 0,
            problems: 0,
          },
          hostTotal: 0,
          hostProblems: 0,
          serviceTotal: 0,
          serviceProblems: 0,
          routers: {
            total: 0,
            problems: 0,
            items: [],
          },
          switchs: {
            total: 0,
            problems: 0,
            items: [],
          },
        };
      }

      if (stores[store_key].region === '') {
        stores[store_key].region = host.tags.region;
      }
      if (host.tags.TIPO_DE_DISPOSITIVO === 'ROUTER') {
        stores[store_key].routers.items.add(host);
        stores[store_key].routers.total += 1;
        stores[store_key].routers.problems += host.state !== 0 ? 1 : 0;
      } else if (host.tags.TIPO_DE_DISPOSITIVO === 'SWITCH') {
        stores[store_key].switchs.items.add(host);
        stores[store_key].switchs.total += 1;
        stores[store_key].switchs.problems += host.state !== 0 ? 1 : 0;
      } else {
        stores[store_key].devices.total += 1;
        stores[store_key].devices.problems += host.state !== 0 ? 1 : 0;
      }
      if (host.uptime) {
        stores[store_key].hosts.withUptime += 1;
        stores[store_key].hosts.withUptimeProblems += host.uptime <= 30;
      }
      stores[store_key].hostTotal += 1;
      stores[store_key].hostProblems += host.state !== 0 ? 1 : 0;
      stores[store_key].serviceTotal += host.num_services;
      stores[store_key].serviceProblems += host.serviceProblems;
    }
  });

  const storeList = Object.values(stores);
  storeList.forEach((store: any) => {
    store['hostProblemsPercent'] = store.devices.total > 0 ? (store.devices.problems / store.devices.total) * 100 : 0;
    store['routersProblemsPercent'] =
      store.routers.total > 0 ? (store.routers.problems / store.routers.total) * 100 : 0;
    store['switchsProblemsPercent'] =
      store.switchs.total > 0 ? (store.switchs.problems / store.switchs.total) * 100 : 0;

    if (store.hosts.withUptime > 0 && store.hosts.withUptimeProblems >= store.hosts.withUptime * 0.8) {
      store.hasUptimeProblems = true;
      store.uptimeProblemsPercent = store.hosts.withUptimeProblems / store.hosts.withUptime / 100;
    }
    store.criticity =
      (store.hostProblemsPercent +
        store.routersProblemsPercent +
        store.switchsProblemsPercent +
        store.uptimeProblemsPercent) /
      4;
    if (
      store.devices.problems > store.devices.total * 0.8 ||
      (store.routersProblemsPercent === 100 && store.switchsProblemsPercent === 100)
    ) {
      store.state = 2;
    } else {
      if (
        store.devices.problems > store.devices.total * 0.5 ||
        (store.routersProblemsPercent > 50 && store.switchsProblemsPercent > 50)
      ) {
        store.state = 1;
      } else {
        store.state = 0;
      }
    }
  });

  return storeList;
}

export function getRegionStatusByStores(stores: any[]) {
  const regions: any = {};
  stores.forEach((store: any) => {
    if (store.region) {
      if (!regions[store.region]) {
        regions[store.region] = {
          //@ts-ignore
          region: region_dictionary[store.region],
          status: store.state,
          storeTotal: 0,
          storeProblems: 0,
          problems: store.serviceProblems,
          percentage: {
            problem: 10,
            all: 100,
          },
        };
      }
      regions[store.region]['storeTotal'] += 1;
      if (store.state !== 0) {
        regions[store.region]['storeProblems'] += 1;
      }

      if (store.state > regions[store.region].status) {
        regions[store.region].status = store.state;
      }
      regions[store.region].problems += store.serviceProblems;
    }
  });
  return Object.values(regions);
}
