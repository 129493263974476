import { PanelPlugin } from '@grafana/data';
import { SimpleOptions } from './types';
import { SimplePanel } from './components/SimplePanel';

export const plugin = new PanelPlugin<SimpleOptions>(SimplePanel).setPanelOptions((builder) => {
  return builder
    .addSelect({
      path: 'dashboardType',
      name: 'Dashboard type',
      defaultValue: 'datacenter',
      settings: {
        options: [
          {
            value: 'datacenter',
            label: 'Datacenters',
          },
          {
            value: 'network',
            label: 'Redes - Pais',
          },
          {
            value: 'noc',
            label: 'NOC',
          },
        ],
      },
    })
    .addTextInput({
      path: 'site_url',
      name: 'URL base de checkmk',
      description: 'El base de sitio checkmk',
      defaultValue: '',
    })
    .addTextInput({
      path: 'enlaces',
      name: 'Relacion entre el nombre de una interfaz con el nombre del punto de monitoreo de un neighbor',
      description: 'El formato es: <punto de monitoreo interface>,<punto de monitoreo neighbor>',
      defaultValue: '',
      settings: {
        useTextarea: true,
      },
    })
    .addTextInput({
      path: 'graphic_enlaces',
      name: 'Relacion entre el nombre de una interfaz con el nombre del punto de monitoreo de un neighbor',
      description:
        'El formato es: <type of router>,<speed>,<host>,<punto de monitoreo interface>,<punto de monitoreo neighbor>',
      defaultValue: '',
      settings: {
        useTextarea: true,
      },
    })
    .addTextInput({
      path: 'store_type_map',
      name: 'Tipo de tienda por código',
      description: 'El formato es: <store_type>,<store_code>',
      defaultValue: '',
      settings: {
        useTextarea: true,
      },
    });
});
