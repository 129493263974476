import React, { useEffect, useState } from 'react';
import { PanelProps } from '@grafana/data';
import { SimpleOptions } from 'types';

import {
  processData,
  // getDevicesByType,
  // getStores,
  getHostsServicesMap,
  getHostDetail,
  getHostDetailDatacenter,
  getGraphicInfo,
  getHostsServicesMapCloud,
  //@ts-ignore
} from '../api/datasource.js';

import NOCPanel from 'views/NOCPanel/NOCPanel';
import NetworkPanel from 'views/NetworkPanel/NetworkPanel';
import DatacenterPanel from 'views/DatacenterPanel/DatacenterPanel';
import Toolbar from 'components/Toolbar/Toolbar';
import logo from '../img/logo_cencosud.png';

import './SimplePanel.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';

interface Props extends PanelProps<SimpleOptions> {
  options: any;
  data: any;
  width: any;
  height: any;
}

export const SimplePanel: React.FC<Props> = ({ options, data, width, height }) => {
  const [services, setServices] = useState<any []>([]);
  const [hostsServices, setHostsServices] = useState({});

  const [hosts, setHosts] = useState<any []>([]);
  const [hostsDatacenter, setHostsDatecenter] = useState<any []>([]);
  // const [links, setLinks] = useState<any []>([]);
  const [selectedDashboard, setSelectedDashboard] = useState(options['dashboardType']);
  const [infoDatacenter, setInfoDatacenter] = useState<any []>([]);
  const [arrayToDraw, setArrayToDraw] = useState<any []>([]);
  const [arrayToDrawList, setArrayToDrawList] = useState<any []>([]);
  const [enlacesProblems, setEnlacesProblems] = useState<any []>([]);
  const [allProblems, setAllProblems] = useState<any []>([]);
  const [servicesCloud, setServicesCloud] = useState<any []>([]);
  const [siteUrl, setSiteUrl] = useState('');
  // const [interfaces, setInterfaces] = useState<any []>([]);
  // const [neighbors, setNeighbors] = useState<any []>([]);

  // useEffect(() => {
  //   if (options && options.graphic_enlaces) {
  //     const arrayInterfaces: any = [];
  //     const arrayNeighbors: any = [];
  //     const arrayEnlaces = options.enlaces.split('\n');
  //     arrayEnlaces.forEach((enl: any) => {
  //       const [type, speed, host, interf, neighbor] = enl.split(',');
  //       arrayInterfaces.push(interf);
  //       arrayNeighbors.push(neighbor);
  //     });
  //     setInterfaces(arrayInterfaces);
  //     setNeighbors(arrayNeighbors);
  //   }
  // }, [options]);

  useEffect(() => {
    const series = processData(data, '$', 'json');
    let hosts: any [] = [];
    let services: any [] = [];
    let links: any [] = [];
    let services_cloud: any [] = [];
    const store_type_map: any = {};
    options['store_type_map'].split('\n').forEach((line: string) => {
      let [value, key] = line.split(',');
      store_type_map[key] = value;
    });
    Object.keys(series).forEach((serie) => {
      let tmp: any [] = [];
      if (serie.endsWith(':host')) {
        tmp = series[serie];
        tmp.forEach((host: any) => {
          if (store_type_map[host.tags['CODIGO_DE_TIENDA']]) {
            host.tags['TIENDA'] = store_type_map[host.tags['CODIGO_DE_TIENDA']];
          }
        });
        hosts = hosts.concat(tmp);
      } else if (serie.endsWith(':service')) {
        services = services.concat(series[serie]);
      } else if (serie.endsWith(':link')) {
        links = links.concat(series[serie]);
      } else if (serie.endsWith(':service_cloud')) {
        services_cloud = services_cloud.concat(series[serie]);
      }
    });

    const arrayInterfaces: any = [];
    const arrayNeighbors: any = [];
    if (options && options.enlaces) {
      const arrayEnlaces = options.enlaces.split('\n');
      arrayEnlaces.forEach((enl: any) => {
        const [interf, neighbor] = enl.split(',');
        arrayInterfaces.push(interf);
        arrayNeighbors.push(neighbor);
      });
      // setInterfaces(arrayInterfaces);
      // setNeighbors(arrayNeighbors);
    }
    const graphicEnlacesInfo: any = {};
    if (options && options.graphic_enlaces) {
      // const arrayInterfaces: any = [];
      // const arrayNeighbors: any = [];
      const arrayEnlaces = options.graphic_enlaces.split('\n');
      arrayEnlaces.forEach((enl: any) => {
        const [type, speed, host, to, interf, neighbor, label] = enl.split(',');
        if (!graphicEnlacesInfo[host]) {
          graphicEnlacesInfo[host] = { type, conections: [] };
        }
        graphicEnlacesInfo[host]['conections'].push({ speed, interf, neighbor, to, label });
        // arrayInterfaces.push(interf);
        // arrayNeighbors.push(neighbor);
      });
      // setInterfaces(arrayInterfaces);
      // setNeighbors(arrayNeighbors);
    }
    // console.log(graphicEnlacesInfo);
    if (options && options.site_url) {
      setSiteUrl(options.site_url);
    }

    const hostServices = getHostsServicesMap(services);
    const hostServicesCloud = getHostsServicesMapCloud(services_cloud, hosts);
    const { arrayToGraphic, arrayToList } = getGraphicInfo(hosts, links, graphicEnlacesInfo);
    setArrayToDraw(arrayToGraphic);
    setArrayToDrawList(arrayToList);
    const { arrayInfoDatacentersByCountry, problemsWithEnlaces, allProblemsServ } = getHostDetailDatacenter(
      hosts,
      hostServicesCloud,
      links,
      arrayInterfaces,
      arrayNeighbors
    );
    const hostsWithDetails = getHostDetail(hosts, hostServices);
    const hostsWithDetailsCloud = getHostDetail(hosts, hostServicesCloud);
    // console.log(hostsWithDetailsCloud);

    setInfoDatacenter(arrayInfoDatacentersByCountry);
    setEnlacesProblems(problemsWithEnlaces);
    setAllProblems(allProblemsServ);
    setServices(services);
    setHosts(hostsWithDetails);
    setHostsDatecenter(hostsWithDetailsCloud);
    setHostsServices(hostServicesCloud);
    // setLinks(links);
    setServicesCloud(hostServicesCloud);
  }, [data]);
  // console.log(links);
  // const theme = useTheme2();
  // const styles = useStyles2(getStyles);
  const dashboardPanels: any = {
    datacenter: {
      title: 'DASHBOARD OPERACIONES - DATACENTER VISTA GENERAL',
      panel: (
        <DatacenterPanel
          hosts={hostsDatacenter}
          services={services}
          infoDatacenter={infoDatacenter}
          arraytoDraw={arrayToDraw}
          arrayToDrawList={arrayToDrawList}
          enlacesProblems={enlacesProblems}
          allProblems={allProblems}
          servicesCloud={servicesCloud}
          siteUrl={siteUrl}
          //     linesEnlaces={processEnlaces}
          //     siteOptions={siteOptions}
          //     interfaces={interfaces}
          //     neighbors={neighbors}
        />
      ),
    },
    network: {
      title: 'DASHBOARD OPERACIONES - REDES PAISES VISTA GENERAL',
      panel: <NetworkPanel hosts={hosts} services={services} infoDatacenter={infoDatacenter} />,
    },
    noc: {
      title: 'DASHBOARD OPERACIONES - NOC VISTA GENERAL',
      panel: (
        <NOCPanel
          hosts={hosts}
          hostsServices={hostsServices}
          infoDatacenter={infoDatacenter}
          onChange={setSelectedDashboard}
        />
      ),
    },
  };
  function getPanelToolbarButons() {
    const toolbarBotons = [
      {
        key: 'datacenter',
        label: 'Ir a datacenters',
        click: () => setSelectedDashboard('datacenter'),
      },
      {
        key: 'network',
        label: 'Ir a Redes Paises',
        click: () => setSelectedDashboard('network'),
      },
      {
        key: 'noc',
        label: 'Ir a NOC',
        click: () => setSelectedDashboard('noc'),
      },
    ];
    return toolbarBotons.filter((button) => button.key !== selectedDashboard);
  }
  return (
    <div className="dashboard-redes-container">
      <div className="toolbar-wrap">
        <Toolbar title={dashboardPanels[selectedDashboard]['title']} actions={getPanelToolbarButons()}>
          <img src={logo} />
        </Toolbar>
      </div>
      <div className="content">
        <div className="network-dashboard">{dashboardPanels[selectedDashboard]['panel']}</div>
      </div>
    </div>
  );
};
