// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.enlace-tooltip-cmpt {
  width: max-content;
  height: max-content;
  display: flex;
  flex-direction: column;
  padding: 10px 10px 6px 10px;
  font-size: 10px;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 8px;
  position: relative;
}

.enlace-tooltip-cmpt .item {
  margin-bottom: 4px;
}

.enlace-tooltip-cmpt .copy {
  position: absolute;
  right: 0;
  top: 6px;
  width: 16px;
  height: auto;
  cursor: pointer;
}

.enlace-tooltip-cmpt .copy img {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./components/EnlaceTooltip/EnlaceTooltip.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,eAAe;EACf,2BAA2B;EAC3B,uBAAuB;EACvB,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,QAAQ;EACR,WAAW;EACX,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,WAAW;AACb","sourcesContent":[".enlace-tooltip-cmpt {\n  width: max-content;\n  height: max-content;\n  display: flex;\n  flex-direction: column;\n  padding: 10px 10px 6px 10px;\n  font-size: 10px;\n  justify-content: flex-start;\n  align-items: flex-start;\n  border-radius: 8px;\n  position: relative;\n}\n\n.enlace-tooltip-cmpt .item {\n  margin-bottom: 4px;\n}\n\n.enlace-tooltip-cmpt .copy {\n  position: absolute;\n  right: 0;\n  top: 6px;\n  width: 16px;\n  height: auto;\n  cursor: pointer;\n}\n\n.enlace-tooltip-cmpt .copy img {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
