import React from 'react';
import './Toolbar.css';

type Props = {
  title: string;
  children: any | React.JSX.Element;
  actions: any;
};

const Toolbar: React.FC<Props> = ({ title, children, actions }) => {
  function getActions() {
    return actions.map((action: any, index: number) => (
      <div key={`act-${index}`} className="button-action" onClick={action.click}>
        {action.label}
      </div>
    ));
  }
  return (
    <section className="toolbar">
      {!!children ? children : <div className="logo"></div>}
      <div className="title">{title}</div>
      <div className="actions">{getActions()}</div>
    </section>
  );
};

export default Toolbar;
