import argentina from '../img/argentina.svg';
import brasil from '../img/brasil.svg';
import chile from '../img/chile.svg';
import colombia from '../img/colombia.svg';
import peru from '../img/peru.svg';
// import uruguay from '../img/uruguay.svg';

export function getStatusColorByType(status: String) {
  if (status === 'OK') {
    return '#00D179';
  } else if (status === 'WARNING' || status === 'WARN') {
    return '#E9B500';
  } else if (status === 'CRITICAL' || status === 'CRIT') {
    return '#D93747';
  } else if (status === 'UNKNOWN' || status === 'UNK') {
    return 'gray';
  }
  return '#E9B500';
}

export function getStatusHoverColorByType(status: String) {
  if (status === 'OK') {
    return 'rgba(0, 209, 121, 0.2)';
  } else if (status === 'WARNING' || status === 'WARN') {
    return 'rgba(233, 181, 0, 0.2)';
  } else if (status === 'CRITICAL' || status === 'CRIT') {
    return 'rgba(217, 55, 71, 0.2)';
  }
  return 'rgba(233, 181, 0, 0.2)';
}

export function getStatusColorByThreshold(value: number, threshold = [75, 80]) {
  if (value >= threshold[1]) {
    return '#D93747';
  } else if (value >= threshold[0]) {
    return '#E9B500';
  } else {
    return '#00D179';
  }
}

export function getStatusColorByNumber(status: any) {
  if (status === 0 || status === '0') {
    return '#00D179';
  } else if (status === 1 || status === '1') {
    return '#E9B500';
  } else if (status === 2 || status === '2') {
    return '#D93747';
  }
  return 'gray';
}

export function getFlags(country: string) {
  if (country.includes('CHL')) {
    return chile;
  } else if (country.includes('ARG')) {
    return argentina;
  } else if (country.includes('COL')) {
    return colombia;
  } else if (country.includes('PER')) {
    return peru;
  } else if (country.includes('BRA')) {
    return brasil;
  } else if (country.includes('URU')) {
  }
  return chile;
}
