import React, { useState } from 'react';

import IconDown from 'img/down.svg';
import IconUp from 'img/up.svg';

import './CardDropdown.css';

type Props = {
  label: any;
  children: any;
};

const CardDropdown: React.FC<Props> = ({ label = 'VISTA DE ANALISIS', children }) => {
  const [arrowDirection, setArrowDirection] = useState(true);
  const [showContent, setShowContent] = useState(false);

  return (
    <section className="card-dropdown-cmpt">
      <div
        className="card-dropdown-title"
        style={{
          borderBottomRightRadius: showContent ? '0' : '10px',
          borderBottomLeftRadius: showContent ? '0' : '10px',
          borderBottom: showContent ? '1px solid #ffffff' : 'none',
        }}
      >
        <div className="card-dropdown-title-label">{label}</div>
        <div
          className="card-dropdown-title-icon"
          onClick={() => {
            setArrowDirection(!arrowDirection);
            setShowContent(!showContent);
          }}
        >
          <img src={arrowDirection === true ? IconDown : IconUp} />
        </div>
      </div>
      {showContent ? <div className="card-dropdown-content">{children}</div> : <></>}
    </section>
  );
};

export default CardDropdown;
