import React from 'react';

// import { getStatusColorByNumber } from '../../utils/color';
import './AlertByDevice.css';

type Props = {
  type: any;
  status?: any;
  hostDowntype: any;
  hostUptype: any;
  servicesProblemstype: any;
  servicesAlltype?: any;
  criticaltype?: any;
  warningtype?: any;
  processingtype?: any;
  oktype?: any;
};

const AlertByDevice: React.FC<Props> = ({
  type,
  status,
  hostDowntype,
  hostUptype,
  servicesProblemstype,
  servicesAlltype,
  criticaltype,
  warningtype,
  processingtype,
  oktype,
}) => {
  return (
    <section className="alert-by-device-cmpt">
      <div className="alert-by-device-head">
        <div className="content-head-wrap">
          <div className="label-alert">{type}</div>
          {/* <div className="status" style={{ background: `${getStatusColorByNumber(status)}` }}></div> */}
        </div>
      </div>
      <div className="alert-by-device-body">
        <div className="info-alert-by-device">
          <div className="number-alerts">{`${hostDowntype} / ${hostUptype}`}</div>
          <div className="label-alerts">DOWN / UP</div>
        </div>
        {/* <div className="info-alert-by-device">
          <div className="number-alerts">{`${servicesProblemstype} / ${servicesAlltype}`}</div>
          <div className="label-alerts">SERVICES</div>
        </div> */}
        <div className="info-alert-by-device">
          <div className="status-indicator" style={{ background: '#ED4C5C' }}>{`${hostDowntype} CRITICAL`}</div>
          <div
            className="status-indicator"
            style={{ background: '#E9B500' }}
          >{`${servicesProblemstype} Problemas`}</div>
          {/* <div className="status-indicator" style={{ background: '#3AA8F0' }}>{`${processingtype} PROCESSING`}</div> */}
          {/* <div className="status-indicator" style={{ background: '#00D179' }}>{`${oktype} OK`}</div> */}
        </div>
      </div>
    </section>
  );
};

export default AlertByDevice;
