import React from 'react';

import './Card.css';

type Props = {
  states: any[];
};

const CardStates: React.FC<Props> = ({ states }) => {
  function getStates() {
    return states.map((state, index) => {
      let value1,
        value2 = 0;
      if (state.value) {
        let arrayValues = state.value.split('/');
        value1 = arrayValues[0];
        value2 = arrayValues[1];
      }
      return (
        <div className="state" key={`${index}-card-states`}>
          <div className="state-value">
            <span style={{ fontWeight: 'bold' }}>{value1} /</span>
            {value2}
          </div>
          <div className="state-name">{state.name} </div>
        </div>
      );
    });
  }
  return <div className="dpa-card-states">{getStates()}</div>;
};

export default CardStates;
