import React from 'react';
import DeviceIcon from 'utils/DeviceIcon';
import { getStatusColorByNumber } from '../../utils/color';

import './CardInfo.css';

type Props = {
  type: any;
  name?: any;
  label: any;
  info: any;
  status: any;
  problems: any;
  device: any;
  ok: any;
  total: any;
};

const CardInfo: React.FC<Props> = ({ status, type, name = '', label = '', info = '', problems, device, ok, total }) => {
  function getCard() {
    //@ts-ignore
    const percentageUp = parseInt((ok * 100) / total, 10);
    // const grayPixels = (78 * percentageProblem) / 100;
    const whitePixels = 100 - percentageUp;
    if (type === 'title') {
      return <div className="title-card">{name}</div>;
    } else if (type === 'info') {
      return (
        <>
          {/* <div className="problems-dt">{`${problems} ALERTAS`}</div> */}
          <div className="problems-dt">
            {/* <img src={getIcons(device)} /> */}
            {/* {getIcons(device)} */}
            <DeviceIcon type={device} color={status !== 0 ? '#FFFFFF' : '#81848E'} />
          </div>
          <div className="title-card">{`${ok} / ${total}`}</div>
          <div className="info-card">{label}</div>
          <div className="graphic" style={{ width: '100%', height: '6px', display: 'flex' }}>
            <div
              className="graphic-gray"
              style={{ width: `${percentageUp}%`, height: '100%', background: '#5d616b' }}
            ></div>
            <div
              className="graphic-white"
              style={{ width: `${whitePixels}%`, height: '100%', background: '#d9d9d9' }}
            ></div>
          </div>
        </>
      );
    } else {
      return null;
    }
  }
  // .sdwan-card-cmpt .sdwan-card-graphic .graphic .graphic-gray {
  //   background-color: #5d616b;
  // }

  // .sdwan-card-cmpt .sdwan-card-graphic .graphic .graphic-white {
  //   background-color: #d9d9d9;
  // }

  return (
    <section
      className="card-info-basic"
      style={{
        border: status === 0 ? `2px solid ${getStatusColorByNumber(status)}` : 'none',
        backgroundColor: status === 0 ? '#FFFFFF' : getStatusColorByNumber(status),
        color: status === 0 ? '#81848E' : '#ffffff',
        alignItems: type === 'title' ? 'flex-start' : 'flex-end',
      }}
    >
      {getCard()}
    </section>
  );
};

export default CardInfo;
