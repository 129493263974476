// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-info-basic {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  border-radius: 10px;
  position: relative;
}

.card-info-basic .title-card {
  font-size: 16px;
  font-weight: bold;
  /* color: #ffffff; */
}

.card-info-basic .info-card {
  font-size: 8px;
  /* color: #ffffff; */
}

.card-info-basic .problems-dt {
  position: absolute;
  /* color: white; */
  font-size: 8px;
  font-weight: bold;
  left: 10px;
  top: 10px;
}
`, "",{"version":3,"sources":["webpack://./components/CardInfo/CardInfo.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,aAAa;EACb,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,oBAAoB;AACtB;;AAEA;EACE,cAAc;EACd,oBAAoB;AACtB;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,cAAc;EACd,iBAAiB;EACjB,UAAU;EACV,SAAS;AACX","sourcesContent":[".card-info-basic {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  padding: 10px;\n  border-radius: 10px;\n  position: relative;\n}\n\n.card-info-basic .title-card {\n  font-size: 16px;\n  font-weight: bold;\n  /* color: #ffffff; */\n}\n\n.card-info-basic .info-card {\n  font-size: 8px;\n  /* color: #ffffff; */\n}\n\n.card-info-basic .problems-dt {\n  position: absolute;\n  /* color: white; */\n  font-size: 8px;\n  font-weight: bold;\n  left: 10px;\n  top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
