// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toolbar {
  width: 100%;
  height: 67px;
  display: flex;
  align-items: center;
  background: #eff3fc;
  position: relative;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  z-index: 1;
}

.toolbar .logo {
  height: 100%;
  width: 120px;
  background-repeat: no-repeat;
  background-size: auto 70%;
  background-position: center;
}

.toolbar img {
  height: 100%;
}

.toolbar .title {
  color: #007cc1;
  font-weight: bold;
  font-size: 20px;
  margin-left: 8px;
}

.toolbar .actions {
  position: absolute;
  display: flex;
  right: 16px;
}

.toolbar .actions .button-action {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 20px;
  background-color: #428bc1;
  color: #ffffff;
  border-radius: 16px;
  cursor: pointer;
  margin-left: 16px;
}
`, "",{"version":3,"sources":["webpack://./components/Toolbar/Toolbar.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,kBAAkB;EAClB,2FAA2F;EAC3F,UAAU;AACZ;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,4BAA4B;EAC5B,yBAAyB;EACzB,2BAA2B;AAC7B;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,cAAc;EACd,iBAAiB;EACjB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,WAAW;AACb;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,iBAAiB;EACjB,yBAAyB;EACzB,cAAc;EACd,mBAAmB;EACnB,eAAe;EACf,iBAAiB;AACnB","sourcesContent":[".toolbar {\n  width: 100%;\n  height: 67px;\n  display: flex;\n  align-items: center;\n  background: #eff3fc;\n  position: relative;\n  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;\n  z-index: 1;\n}\n\n.toolbar .logo {\n  height: 100%;\n  width: 120px;\n  background-repeat: no-repeat;\n  background-size: auto 70%;\n  background-position: center;\n}\n\n.toolbar img {\n  height: 100%;\n}\n\n.toolbar .title {\n  color: #007cc1;\n  font-weight: bold;\n  font-size: 20px;\n  margin-left: 8px;\n}\n\n.toolbar .actions {\n  position: absolute;\n  display: flex;\n  right: 16px;\n}\n\n.toolbar .actions .button-action {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 8px 20px;\n  background-color: #428bc1;\n  color: #ffffff;\n  border-radius: 16px;\n  cursor: pointer;\n  margin-left: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
