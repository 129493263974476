type Locations = {
  TAR: string;
  ANT: string;
  ATA: string;
  COQ: string;
  VAL: string;
  OHI: string;
  MAU: string;
  BIO: string;
  ARA: string;
  LAG: string;
  ARI: string;
  SGO: string;
};

const region_dictionary: Locations = {
  TAR: 'Tarapaca',
  ANT: 'Antofagasta',
  ATA: 'Atacama',
  COQ: 'Coquimbo',
  VAL: 'Valparaiso',
  OHI: 'Libertador_General_Bernardo_OHiggins',
  MAU: 'Maule',
  BIO: 'Bio-Bio',
  ARA: 'La_Araucania',
  LAG: 'Los_Lagos',
  ARI: 'Arica_y_Parinacota',
  SGO: 'Region_Metropolitana_de_Santiago',
  // BUE: 'Buenos_Aires',
  // CON: 'Concon',
  // CUR: 'Curico',
  // IND: 'Independencia',
  // LAN: 'Lanco',
  // LIN: 'Linares',
  // NUB: 'Nuble',
  // OSO: 'Osorno',
  // QUI: 'Quilpue',
  // RAN: 'Rancagua',
  // TAL: 'Talca',
  // TEM: 'Temuco',
  // VAL: 'Valdivia',
};
const region_number = {
  Tarapaca: 'I',
  Antofagasta: 'II',
  Atacama: 'III',
  Coquimbo: 'IV',
  Valparaiso: 'V',
  Libertador_General_Bernardo_OHiggins: 'VI',
  Maule: 'VII',
  'Bio-Bio': 'VIII',
  La_Araucania: 'IX',
  Los_Lagos: 'X',
  Aisen_del_General_Carlos_Ibanez_del_Campo: 'XI',
  Magallanes_y_Antartica_Chilena: 'XII',
  Los_Rios: 'XIV',
  Arica_y_Parinacota: 'XV',
  Region_Metropolitana_de_Santiago: 'RM',
};

export { region_dictionary, region_number };
